import React from 'react';
import { ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LaddaButton from 'react-ladda';
import Translate from '../../../i18n/Translate';
import ModalComponent from '../ModalComponent';
import logo from '../../../assets/img/brand/securitize_logo_with_text.svg';
import { disableReverseSolicitationModal } from '../../../utils/helpers/actions';
import './MarketReverseSolicitationModal.scss';


const MarketReverseSolicitationModal = ({
  onToggle,
  isOpen,
  options: {
    redirect,
    onClose,
  },
  disableReverseSolicitationModal,
  email,
}) => {
  const onAccept = () => {
    disableReverseSolicitationModal(email);
    redirect();
    onToggle();
  };

  const onCancel = () => {
    if (onClose) onClose();
    onToggle();
  };

  return (
    <ModalComponent
      title=""
      image={logo}
      isOpen={isOpen}
      onToggle={onCancel}
      name="MarketReverseSolicitationModal"
      className="market-modal-container"
    >
      <div className="modal-header-title mb-3 text-center">
        <h4 className="font-20">
          <Translate translationKey="Texts.reverse-solicitation-modal-title" />
        </h4>
      </div>
      <p className="modal-description font-18 mb-4 subtext">
        <Translate translationKey="Texts.reverse-solicitation-modal-description" />
      </p>
      <p className="font-12 subtext">
        <Translate translationKey="Texts.reverse-solicitation-modal-disclaimer" />
      </p>
      <ModalFooter className="border-0 p-0 flex-column">
        <LaddaButton
          id="reverse-solicitation-modal-accept"
          className="btn btn-link m-0"
          variant="link"
          size="lg"
          onClick={onAccept}
        >
          <Translate translationKey="Texts.reverse-solicitation-confirm-button" />
        </LaddaButton>
      </ModalFooter>
    </ModalComponent>
  );
};


MarketReverseSolicitationModal.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    redirect: PropTypes.func.isRequired,
    onClose: PropTypes.func,
  }).isRequired,
  disableReverseSolicitationModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

const mapStateToProps = ({ helpers: { modal, options }, auth: { user } }) => ({
  options,
  isError: modal.isError,
  loading: modal.loading,
  email: user?.email,
  reverseSolicitation: user?.reverseSolicitation,
});

export default connect(mapStateToProps, { disableReverseSolicitationModal })(MarketReverseSolicitationModal);
