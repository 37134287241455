/* const EUCountries = [
  'BE', 'BG', 'CZ', 'DK', 'DE', 'DE', 'EE', 'IE', 'EL',
  'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU',
  'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE']; */

// MixPanel is loaded asynchronously due to GDPR, so on failure we retry later
// to see if it's loaded/allowed now.
const withRetries = (
  fn,
  attempts,
  timeout,
  err,
) => {
  try {
    fn();
  } catch (e) {
    if (attempts > 0) {
      setTimeout(() => withRetries(fn, attempts - 1, timeout, err), timeout);
    } else {
      err(e);
    }
  }
};

export const MixPanel = {
  alias: (investorId) => {
    withRetries(
      () => {
        window.mixpanel.alias(investorId);
      },
      3,
      5000,
      (e) => console.warn('Mixpanel is not active', e, e.stack),
    );
  },
  identify: (uniqueIdentifier) => {
    withRetries(
      () => {
        window.mixpanel.identify(uniqueIdentifier);
      },
      3,
      5000,
      (e) => console.warn('Mixpanel is not active', e, e.stack),
    );
  },
  register: (data) => {
    withRetries(
      () => {
        window.mixpanel.register(data);
      },
      3,
      5000,
      (e) => console.warn('Mixpanel is not active', e, e.stack),
    );
  },
  track: (event, data) => {
    withRetries(
      () => {
        window.mixpanel.track(event, data);
      },
      3,
      5000,
      (e) => console.warn('Mixpanel is not active', e, e.stack),
    );
  },
  getMixpanelId: () => {
    withRetries(
      () => window.mixpanel.get_distinct_id() ?? '',
      3,
      5000,
      (e) => console.warn('Mixpanel is not active', e, e.stack),
    );
  },
  resetMixPanel: () => {
    withRetries(
      () => {
        window.mixpanel.reset();
      },
      3,
      5000,
      (e) => console.warn('Mixpanel is not active', e, e.stack),
    );
  },
  people: {
    set: (params) => {
      withRetries(
        () => {
          window.mixpanel.people.set(params);
        },
        3,
        5000,
        (e) => console.warn('Mixpanel is not active', e, e.stack),
      );
    },
    set_once: (params) => {
      withRetries(
        () => {
          window.mixpanel.people.set_once(params);
        },
        3,
        5000,
        (e) => console.warn('Mixpanel is not active', e, e.stack),
      );
    },
  },
};
