import { GET_FEATURE_FLAGS } from './actionTypes';


const defaultState = {
  isLoading: false,
  isError: false,
  data: { },
};

export const featureFlags = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_FEATURE_FLAGS.INIT:
      return {
        ...state,
        isError: false,
        isLoading: true,
      };
    case GET_FEATURE_FLAGS.ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    case GET_FEATURE_FLAGS.SUCCESS:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: payload,
      };
    default:
      return state;
  }
};
