import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Translate from '../../../../i18n/Translate';

const DefaultContent = ({
  title, status, paragraph, size,
}) => {
  const getStatus = {
    'documents-expired': 'Texts.status-documents-expired',
    'update-documents': 'Texts.status-update-documents',
    'updates-required': 'Texts.status-updates-required',
    'not-qualified': 'Texts.status-not-qualified',
    'not-completed': 'Texts.status-not-completed',
    processing: 'Texts.status-in-review',
    completed: 'Texts.status-completed',
    rejected: 'Texts.status-rejected',
    expired: 'Texts.status-expired',
  };

  return (
    <>
      <h6
        className={classNames('set-card_data__title', {
          'font-16': size === 'small',
          'font-20': size === 'big',
        })}
      >
        <Translate translationKey={title} />
      </h6>
      {
        status && (
          <p
            className={classNames('set-card_data__status', {
              'set-card_data__status--not-completed': status === 'not-completed',
              'set-card_data__status--processing': status === 'processing',
              'set-card_data__status--completed': status === 'completed',
              'set-card_data__status--rejected': status === 'rejected',
              'set-card_data__status--not-qualified ': status === 'not-qualified',
              'set-card_data__status--updates-required': status === 'updates-required',
              'set-card_data__status--expired': status === 'expired',
              'set-card_data__status--documents-expired': status === 'documents-expired',
              'set-card_data__status--update-documents': status === 'update-documents',
              'font-14': size === 'small',
              'font-20': size === 'big',
            })}
          >
            <Translate translationKey={getStatus[status]} />
          </p>
        )
      }
      {
        paragraph && (
          <p
            className={classNames('text-muted', {
              'font-14': size === 'small',
              'font-20': size === 'big',
            })}
          >
            <Translate translationKey={paragraph} />
          </p>
        )
      }
    </>
  );
};

DefaultContent.defaultProps = {
  status: '',
  paragraph: '',
  size: 'small',
};

DefaultContent.propTypes = {
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
  status: PropTypes.string,
  size: PropTypes.oneOf(['small', 'big']),
};

export default DefaultContent;
