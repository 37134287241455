
import { getWizardStatusNext } from '../../containers/DefaultLayout/components/MainMenu/actionTypes';
import { updateInvestorTypeNext } from '../../views/Profile/verificationProfile/InvestorType/actionTypes';

const defaultState = {
  isLoading: true,
  isReady: false,
  isError: false,
  status: {},
};

export const wizardStatus = (state = defaultState, { type, payload }) => {
  switch (type) {
    case updateInvestorTypeNext.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case getWizardStatusNext.SUCCESS:
      return {
        isLoading: false,
        isReady: true,
        status: {
          ...state.status,
          ...(payload || {}),
        },
      };
    case getWizardStatusNext.ERROR:
      return {
        ...state,
        isLoading: false,
        isReady: true,
        isError: true,
      };

    default:
      return state;
  }
};
