import { withRouter } from 'react-router-dom';
import { useEffect } from 'react';

const updateGaPage = ({ pathname: path }) => {
  if (window.ga && typeof window.ga === 'function') {
    window.ga('set', 'page', path);
    window.ga('send', 'pageview');
  }
};

const RouterFollow = ({ history }) => {
  const { location } = history;

  useEffect(
    () => {
      updateGaPage(location);
      const unlisten = history.listen(updateGaPage);
      return unlisten;
    },
    [],
  );


  return [];
};

export default withRouter(RouterFollow);
