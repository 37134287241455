import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useVerificationRoutes } from '../../../../views/Profile/verificationProfile/routes';
import { WizardMenuItem } from '../../../../components/SidebarNav';
import { updateRedirectUrl } from '../../../../utils/helpers/actions';
import { useGetWizardStatus } from '../../../../api/hooks/wizard-status/useGetWizardStatus';

const WizardMenu = ({
  currentType, user, routes,
}) => {
  const { data: wizardStatus, isLoading, refetch } = useGetWizardStatus();

  const [nextStep, setNextStep] = useState('type');
  const { getVerificationRoutes, getStepsOrder } = useVerificationRoutes();
  const kycRoutes = getVerificationRoutes();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { redirectUrl } = queryString.parse(location.search) || {};
  const isUpdatesRequired = ['documents-expired', 'updates-required']
    .includes(user.verificationStatus)
    && user.investorType === 'individual';

  useEffect(() => {
    if (!redirectUrl) return;
    dispatch(updateRedirectUrl(redirectUrl));
  }, []);


  useEffect(() => () => dispatch(updateRedirectUrl(null)), []);

  let isCompleteSeries = true;
  const routeUpdate = (route) => {
    const separator = 'verification/';
    const point = route.path.split('?')[0].slice(route.path.indexOf(separator) + separator.length);
    const stepStatus = wizardStatus?.[point];
    if (isCompleteSeries && (stepStatus && !stepStatus.isComplete)) {
      isCompleteSeries = false;
    }
    return stepStatus ? {
      ...route,
      ...stepStatus,
      isComplete: isUpdatesRequired || (stepStatus.isComplete && isCompleteSeries),
    } : route;
  };

  const updateNextStep = () => {
    const { investorType } = user;
    if (!investorType) {
      return;
    }
    const checkSteps = getStepsOrder(investorType);
    const nextStep = checkSteps.find((step) => !wizardStatus?.[step] || !wizardStatus?.[step].isComplete);
    setNextStep(nextStep);
    return nextStep;
  };

  useEffect(() => {
    refetch();
    if (isUpdatesRequired) {
      history.replace({ pathname: '/profile/verification/resubmit', search: location.search });
    }
  }, [location.pathname, user && user.investorType]);

  useEffect(() => {
    updateNextStep();
  }, [wizardStatus]);

  useEffect(() => {
    if (isLoading === false) {
      const nextStep = updateNextStep();
      const nextRoute = kycRoutes.find(
        ({ path }) => path.split('?')[0].endsWith(`/${nextStep}`),
      );
      if (nextRoute && nextRoute.path.split('?')[0] !== location.pathname) {
        history.push(`${nextRoute.path}`);
      }
    }
  }, [isLoading]);
  return (
    isLoading ? (
      <div className="loader animated fadeIn pt-1 text-center">
        <div className="sk-spinner sk-spinner-pulse" />
      </div>
    ) : (
      <>
        {
        routes.filter((route) => route.name).map((route, index) => {
          const updatedRoute = routeUpdate(route);

          return (!!updatedRoute.component && (
            <li
              key={updatedRoute.path}
              className={classNames('wizard-menu-item', 'animated', 'fadeIn', {
                'wizard-menu-item_parent': updatedRoute.childRoutes && currentType,
                disabled: !user || isUpdatesRequired || !['not-verified', 'updates-required', 'documents-expired', 'expired']
                  .includes((user || {}).verificationStatus),
              })}
            >
              <WizardMenuItem id={`wizard-menu-${index}`} {...updatedRoute} nextStep={nextStep} /> {/* eslint-disable-line */}
              {updatedRoute.childRoutes && currentType && (
                <ul className="nav">
                  {
                    updatedRoute.childRoutes(currentType).map((child, index) => {
                      const updatedChild = routeUpdate(child);
                      return (
                        !!updatedChild.component && !!updatedChild.name && (
                          <li className="wizard-menu-item animated fadeIn font-12" key={updatedChild.path}>
                            <WizardMenuItem id={`wizard-sub-menu-${index}`} {...updatedChild}  /> {/* eslint-disable-line */}
                          </li>
                        )
                      );
                    })
                  }
                </ul>
              )}
            </li>
          ));
        })
      }
      </>
    )
  );
};

WizardMenu.defaultProps = {
  user: null,
  currentType: '',
};

WizardMenu.propTypes = {
  currentType: PropTypes.string,
  user: PropTypes.objectOf(PropTypes.any),
  routes: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string,
    name: PropTypes.string,
    component: PropTypes.object,
    exact: PropTypes.bool,
    isComplete: PropTypes.bool,
  })).isRequired,
};

export default WizardMenu;
