import { getOpportunitiesFiltersNext, getOpportunitiesNext } from './actionTypes';

const defaultState = {
  isLoading: true,
  isInitialized: false,
  isError: false,
  errorType: '',
  opportunities: [],
  typeOfAssetsFilterItems: [],
  issuersFilterItems: [],
  opportunityNameFilterItems: [],
};

export const market = (state = defaultState, { type, payload }) => {
  switch (type) {
    case getOpportunitiesNext.INIT:
      return {
        ...state,
        isLoading: true,
      };

    case getOpportunitiesFiltersNext.SUCCESS:
      return {
        ...state,
        ...payload,
        errorType: '',
        isInitialized: true,
        isError: false,
      };

    case getOpportunitiesNext.SUCCESS:
      return {
        ...state,
        opportunities: payload,
        isLoading: false,
        errorType: '',
        isError: false,
      };

    case getOpportunitiesNext.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorType: payload.message,
      };

    default:
      return state;
  }
};
