import { translate } from '../../../i18n/Translate';
import { successToast } from '../../../components/Toast';
import { changePasswordNext } from './actionTypes';

const defaultState = {
  isError: false,
  errorType: '',
  loading: false,
};

export const security = (state = defaultState, { type, payload }) => {
  switch (type) {
    case changePasswordNext.INIT:
      return {
        ...state,
        loading: true,
      };
    case changePasswordNext.SUCCESS:
      window.setTimeout(() => successToast(translate('Texts.change-password-success')), 0);
      return {
        ...state,
        isError: false,
        errorType: '',
        loading: false,
      };
    case changePasswordNext.ERROR:
      return {
        ...state,
        isError: true,
        errorType: payload.message,
        loading: false,
      };
    default:
      return state;
  }
};
