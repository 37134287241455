import React from 'react';
import {
  Button, Col, ModalFooter, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import Translate from '../../i18n/Translate';
import notVerifiedUser from '../../assets/img/other/notVerifiedUser.svg';
import SettingsCard from '../../views/Profile/components/SettingsCard/SettingsCard';
import ModalComponent from './ModalComponent';

const NotVerifiedUserModal = ({ onToggle, isOpen }) => (
  <ModalComponent
    title="Texts.you-not-verified"
    image={notVerifiedUser}
    isOpen={isOpen}
    onToggle={onToggle}
    name="NotVerifiedUser"
    userStatus="not-verified"
    contentClassName="not-verify-user-modal"
  >
    <p className="modal-text font-16 m-0 text-center">
      <Translate translationKey="Texts.provide-personal-info-modal" />
    </p>
    <Row className="mt-4">
      <Col lg={6}>
        <SettingsCard
          id="complete-details-card"
          size="small"
          href="/profile/verification/type"
          className="mr-3"
        >
          <p className="m-0 ml-3 font-14 my-auto">
            <Translate translationKey="Texts.complete-details" />
          </p>
        </SettingsCard>
      </Col>
    </Row>
    <ModalFooter className="border-0 p-0 flex-column mb-2">
      <Button className="m-0" color="primary" size="lg" block onClick={onToggle}>
        <Translate translationKey="Texts.got-it" />
      </Button>
    </ModalFooter>
  </ModalComponent>
);

NotVerifiedUserModal.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default NotVerifiedUserModal;
