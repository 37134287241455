import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Toast.scss';

const Toast = ({ icon, children }) => (
  <div className="securitize-toast">
    <i className={classNames('securitize-toast__icon', icon)} />
    <div className="securitize-toast__body">
      {
        children
        && (
          <div className="securitize-toast__body__content">
            {children}
          </div>
        )
      }
    </div>
  </div>
);

Toast.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
};

Toast.defaultProps = {
  icon: null,
  children: null,
};

export default Toast;
