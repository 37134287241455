import React, { useEffect } from 'react';
import './ErrorPage.scss';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { translate } from '../../../i18n/Translate';
import { errorToast } from '../../../components/Toast';

const ErrorPage = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { email, fullName, entityName } = useSelector(({ auth }) => auth.user) || {};
  const { error, reason, source } = queryString.parse(search) || {};

  const handelForbidden = () => {
    const name = entityName || fullName || email || '';
    let errorMessage = translate('Errors.not-allowed-to-enter', { name });

    if (reason === 'viewer_role') {
      if (source === 'ats') errorMessage = translate('Errors.not-allowed-to-trade-ats', { name });
      if (source === 'fundraise') errorMessage = translate('Errors.not-allowed-to-enter-fundraise', { name });
      if (source === 'cash-account') errorMessage = translate('Errors.not-allowed-to-enter-cash-account', { name });
    }

    history.push('/portfolio');
    return () => errorToast(errorMessage);
  };

  useEffect(() => {
    if (error === 'forbidden') return handelForbidden();

    history.push('/portfolio');
  }, []);

  return <></>;
};

ErrorPage.defaultProps = {};

ErrorPage.propTypes = {};

export default ErrorPage;
