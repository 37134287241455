import React, { useState } from 'react';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import {
  Alert, Button, FormGroup, ModalFooter,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Password from '../Password';
import Translate from '../../i18n/Translate';
import key2FA from '../../assets/img/other/key.svg';
import { showModal } from '../../utils/helpers/actions';
import ModalComponent from './ModalComponent';
import { checkPWD, getTFASeed } from '../../views/Profile/AccountPage/actions';
import './TwoFactorModal.scss';

const TFAPasswordModal = ({
  onToggle, isOpen, checkPWD, getTFASeed, loading, isError, errorType, tfaEnabled, showModal,
}) => {
  const [password, onChangePwd] = useState('');

  const onNext = () => {
    checkPWD(password, tfaEnabled ? () => showModal('LoginWithTFA') : getTFASeed);
  };

  return (
    <ModalComponent
      title="Texts.2fa"
      image={key2FA}
      isOpen={isOpen}
      onToggle={onToggle}
      name="TFAPassword"
      counter="1 / 2"
    >
      <>
        <p className="modal-text font-16 m-0 text-center">
          {tfaEnabled
            ? <Translate translationKey="Texts.disable-2fa-password" />
            : <Translate translationKey="Texts.enter-current-password" />}
        </p>
        <FormGroup className="pt-3">
          <Password
            id="twofa-password"
            name="password"
            translationKey="Texts.password"
            value={password}
            onChange={(e) => onChangePwd(e.target.value)}
          />
        </FormGroup>
        <ModalFooter className="border-0 p-0 flex-column">
          <LaddaButton
            id="twofa-next"
            className="btn m-0 p-3 btn-primary btn-block font-weight-bold text-white"
            loading={loading}
            color="primary"
            data-style={ZOOM_OUT}
            onClick={onNext}
            disabled={password.length < 8}
            type="button"
          >
            <Translate translationKey="Texts.next" />
          </LaddaButton>
          <Button
            id="twofa-later"
            className="m-0 mt-1"
            size="lg"
            color="link"
            block
            onClick={onToggle}
          >
            <Translate translationKey="Texts.do-later" />
          </Button>
        </ModalFooter>
        {
          isError && (
            <div className="mt-1 mb-0 form-group">
              <Alert color="danger mb-0">
                <Translate translationKey={errorType} />
              </Alert>
            </div>
          )
        }
      </>
    </ModalComponent>
  );
};

TFAPasswordModal.propTypes = {
  onToggle: PropTypes.func.isRequired,
  getTFASeed: PropTypes.func.isRequired,
  checkPWD: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  tfaEnabled: PropTypes.bool.isRequired,
  errorType: PropTypes.string.isRequired,
};

const mapStateToProps = ({ helpers: { modal }, auth: { user } }) => ({
  loading: modal.loading,
  isError: modal.isError,
  errorType: modal.errorType,
  tfaEnabled: user.tfaEnabled,
});

export default connect(
  mapStateToProps,
  {
    getTFASeed,
    checkPWD,
    showModal,
  },
)(TFAPasswordModal);
