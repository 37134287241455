import { combineReducers } from 'redux';
import { personalInfo } from './Individual/PersonalInfo/reducer';
import { entityInfo } from './Entity/InfoPage/reducer';
import { security } from './AccountPage/reducer';
import { verification } from './verificationProfile/reducer';
import { additionalInfo } from './Individual/AdditionalInfo/reducer';
import { legalInfo } from './verificationProfile/LegalInformation/reducer';
import { investorDocs } from './verificationProfile/StatusCheck/reducer';
import { addressDocs } from './verificationProfile/AddressProof/reducer';
import { legalSigners } from './LegalSigner/reducer';
import { accreditation } from './AccreditationPage/reducer';
import { authorizedAccounts } from './AuthorizedAccountsPage/reducer';

export const profile = combineReducers({
  security,
  personalInfo,
  verification,
  entityInfo,
  additionalInfo,
  investorDocs,
  legalInfo,
  addressDocs,
  legalSigners,
  accreditation,
  authorizedAccounts,
});
