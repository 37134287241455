import { translate } from '../../../i18n/Translate';
import { errorToast } from '../../../components/Toast';
import { sessionsGetNext } from '../AccountPage/components/SignedInDevices/actionTypes';
import { getAuthorizeAccountInvitationsNext, sendAuthorizeAccountInvitationsNext } from './actionTypes';

const defaultState = {
  isLoading: false,
  isError: false,
  authorizedAccounts: [],
};

export const authorizedAccounts = (state = defaultState, { type, payload }) => {
  switch (type) {
    case getAuthorizeAccountInvitationsNext.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorType: '',
      };
    case sessionsGetNext.SUCCESS:
    case getAuthorizeAccountInvitationsNext.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        authorizedAccounts: payload || [],
      };
    case sendAuthorizeAccountInvitationsNext.ERROR:
      if (payload.statusCode === 404) {
        window.setTimeout(() => errorToast(translate('Text.email-not-associated-with-SID-investor')), 0);
      }
      return {
        ...state,
      };
    case getAuthorizeAccountInvitationsNext.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorType: payload.message || 'server error',
      };
    default:
      return state;
  }
};
