import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import './TFADigits.scss';
import Translate from '../../i18n/Translate';

class TwoFaDigits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inp0: '',
      inp1: '',
      inp2: '',
      inp3: '',
      inp4: '',
      inp5: '',
    };

    this.pasteFlag = false;
  }

  componentDidMount() {
    setTimeout(this.focusInput, 0);
  }

  handleTwoFactorAuthChange = ({ target }) => {
    const { name, value } = target;
    const isCompliteTwoFactorAuthString = value.length === 6;
    if (isCompliteTwoFactorAuthString) {
      this.setPastedValues(value);
    }
    const _value = value.length > 0 && value.indexOf(' ') !== -1 ? value.split(' ')[1] : value;
    if (!this.pasteFlag) {
      // replace old value
      let newVal = '';
      _value.length <= 1 ? newVal = _value : [newVal] = _value;
      // only digits or empty state
      if (newVal && !/\d/.test(newVal)) return;

      if (isCompliteTwoFactorAuthString) {
        this.focusInput(5);
      } else {
        const numberOfInp = +name.split('inp')[1] || 0;
        this.focusInput(numberOfInp + (newVal ? 1 : -1));
      }

      this.setState({ [name]: newVal }, () => {
        // call the callback function when all values are set
        const {
          inp0, inp1, inp2, inp3, inp4, inp5,
        } = this.state;
        const { callback } = this.props;
        const fullCode = inp0 + inp1 + inp2 + inp3 + inp4 + inp5;
        callback(fullCode);
      });
    }
  };

  onKeyUp = ({ target, keyCode }) => {
    const { value, name } = target;
    const numberOfInp = +name.split('inp')[1] || 0;

    if (!value && keyCode === 8) {
      this.focusInput(numberOfInp - 1, true);
    }

    if (keyCode === 37) {
      this.focusInput(numberOfInp - 1);
    }
    if (keyCode === 39) {
      this.focusInput(numberOfInp + 1);
    }
  };

  focusInput = (inputNumber, clear) => {
    const inputName = `inp${inputNumber || 0}`;
    this[inputName] && this[inputName].focus();
    clear && this.setState({ [inputName]: '' });
  };

  handelPaste = ({ clipboardData }) => {
    const values = clipboardData.getData('Text').split('').slice(0, 6);
    this.setPastedValues(values);
  }

  setPastedValues = (values) => {
    this.pasteFlag = true;
    const result = {};
    (Array.isArray(values) ? values : values.split('')).forEach((val, index) => {
      result[`inp${index}`] = val;
    });
    this.setState(result);
    this.pasteFlag = false;
  };

  render() {
    const { id } = this.props;
    const {
      inp0,
      inp1,
      inp2,
      inp3,
      inp4,
      inp5,
    } = this.state;

    return (
      <div className="six-digits-code d-flex flex-column justify-content-center mx-auto pt-3">
        <p className="font-14 m-0">
          <Translate translationKey="Texts.insert-2fa-code" />
        </p>
        <div className="d-flex mt-3">
          <Input
            name="inp0"
            id={`${id}-inp0`}
            className="text-center py-sm-4 mr-sm-1"
            value={inp0}
            type="tel"
            innerRef={(e) => this.inp0 = e}
            onChange={this.handleTwoFactorAuthChange}
            onPaste={this.handelPaste}
            onKeyUp={this.onKeyUp}
          />
          <Input
            name="inp1"
            id={`${id}-inp1`}
            className="mx-sm-2 py-sm-4 text-center"
            value={inp1}
            type="tel"
            innerRef={(e) => this.inp1 = e}
            onChange={this.handleTwoFactorAuthChange}
            onPaste={this.handelPaste}
            onKeyUp={this.onKeyUp}
          />
          <Input
            name="inp2"
            id={`${id}-inp2`}
            className="text-center py-sm-4 mx-sm-1"
            value={inp2}
            type="tel"
            innerRef={(e) => this.inp2 = e}
            onChange={this.handleTwoFactorAuthChange}
            onPaste={this.handelPaste}
            onKeyUp={this.onKeyUp}
          />
          <Input
            name="inp3"
            id={`${id}-inp3`}
            type="tel"
            className="text-center py-sm-4 mx-sm-2"
            value={inp3}
            innerRef={(e) => this.inp3 = e}
            onChange={this.handleTwoFactorAuthChange}
            onPaste={this.handelPaste}
            onKeyUp={this.onKeyUp}
          />
          <Input
            name="inp4"
            id={`${id}-inp4`}
            type="tel"
            className="text-center py-sm-4 mr-sm-1"
            value={inp4}
            innerRef={(e) => this.inp4 = e}
            onChange={this.handleTwoFactorAuthChange}
            onPaste={this.handelPaste}
            onKeyUp={this.onKeyUp}
          />
          <Input
            name="inp5"
            id={`${id}-inp5`}
            type="tel"
            className="text-center py-sm-4 ml-sm-2"
            value={inp5}
            innerRef={(e) => this.inp5 = e}
            onChange={this.handleTwoFactorAuthChange}
            onPaste={this.handelPaste}
            onKeyUp={this.onKeyUp}
          />
        </div>
      </div>
    );
  }
}

TwoFaDigits.defaultProps = {
  callback: () => {},
  id: '',
};

TwoFaDigits.propTypes = {
  callback: PropTypes.func,
  id: PropTypes.string,
};

export default TwoFaDigits;
