import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import Cookies from 'js-cookie';
import './App.scss';
import 'ladda/dist/ladda-themeless.min.css';

import {
  QueryClientProvider,
} from '@tanstack/react-query';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import { onErrorMiddleware } from './http/http';
import store, { queryClient } from './store';
import { logoutUserUI } from './auth/actions';
import { authRequestStatusNext, getIssuerInfoNext } from './views/Authorize/actionTypes';
import { checkPWDNext } from './views/Profile/AccountPage/actionTypes';

// Containers
import DefaultLayout from './containers/DefaultLayout';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;


axios.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: Cookies.get('auth-token'),
  },
}));

axios.interceptors.response.use((response) => response,
  (error) => {
    if (error.response.status === 401) {
      Cookies.remove('auth-token');

      try {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.auth) {
          window.webkit.messageHandlers.auth.postMessage('auth-token-removed');
        } else {
          // console.log('The webkit messageHandlers or auth handler is not available.');
        }
      } catch (error) {
        console.error('Error posting message to webkit:', error);
      }

      store.dispatch(logoutUserUI());
    }
    return Promise.reject(error);
  });

onErrorMiddleware((next, dispatchActions, data, error) => {
  const { status } = error;

  if (
    status === 401
    && next.seed !== authRequestStatusNext.seed
    && next.seed !== getIssuerInfoNext.seed
    && next.seed !== checkPWDNext.seed
  ) {
    Cookies.remove('auth-token');
    try {
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.auth) {
        window.webkit.messageHandlers.auth.postMessage('auth-token-removed');
      } else {
        // console.log('The webkit messageHandlers or auth handler is not available.');
      }
    } catch (error) {
      console.error('Error posting message to webkit:', error);
    }

    dispatchActions(logoutUserUI());
  }
});

const loading = () => (
  <div className="loader animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />

  </div>
);

const App = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} /> {/* eslint-disable-line */}
        </Switch>
      </React.Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>
);

export default App;
