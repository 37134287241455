import { useQuery } from '@tanstack/react-query';
import {
  getWizardStatus,
} from '../../wizard-status';

const queryKey = 'wizard-status';
const getAddressInfoKey = [queryKey, 'get-wizard-status'];

export const useGetWizardStatus = (options = {}) => useQuery(
  getAddressInfoKey,
  () => getWizardStatus(),
  options,
);
