import { SAVE_TEMP_DATA } from './actionTypes';

const defaultState = {
  isLoading: false,
  isError: false,
  tempData: {},
  errors: {},
};

export const register = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SAVE_TEMP_DATA:
      return {
        ...state,
        tempData: {
          ...state.tempData,
          ...payload,
        },
      };
    default:
      return state;
  }
};
