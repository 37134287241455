import Next from '../http/Next';

export const loginNext = new Next('/login', 'USER_LOGIN', { method: 'post' });
export const sessionNext = new Next('/session', 'USER_SESSION');
export const logoutNext = new Next('/logout', 'USER_LOGOUT');
export const resetPassNext = new Next('/reset-password', 'RESET_PASSWORD', { method: 'post' });
export const renderInvestorNext = new Next('/login/investor/{investorId}', 'RENDER_INVESTOR', { method: 'post' });
export const renderHiddenInvestorNext = new Next('/login/investor/{investorId}', 'RENDER_HIDDEN_INVESTOR', { method: 'post' });
export const activateHiddenInvestorNext = new Next(
  '/activate-hidden-investor', 'ACTIVATE_HIDDEN_INVESTOR', { method: 'POST' },
);
export const UI_LOG_OUT = 'UI_LOG_OUT';
export const HIDE_LOG_OUT = 'HIDE_LOG_OUT';
export const getReverseSolicitationNext = new Next('/reverse-solicitation', 'REVERSE_SOLICITATION');
export const UPDATE_VERIFICATION_STATUS = 'UPDATE_VERIFICATION_STATUS';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_INIT = 'VERIFY_EMAIL_INIT';
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR';
