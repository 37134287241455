import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { getUserSession, loginUser } from '../../auth/actions';
import { setIntent } from '../../utils/intents';
import { AUTHORIZATION_INTENT } from '../../utils/securitizeIdIntents';
import { MixPanel } from '../../utils/helpers/mixPanel';

const CreateAccount = React.lazy(() => import('../../views/SystemPages/Registration/CreateAccount/CreateAccount'));

const loading = () => (
  <div className="loader animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const Login = Loadable({
  loader: () => import('../../views/SystemPages/Login'),
  loading,
});

class SecureRoute extends Component {
  static propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    getUserSession: PropTypes.func,
    loginUser: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    isLoading: PropTypes.bool,
    component: PropTypes.elementType,
    token: PropTypes.string,
    analyticsPageVisitEvent: PropTypes.string,
  };

  static defaultProps = {
    getUserSession: () => undefined,
    loginUser: () => undefined,
    isAuthenticated: false,
    isLoading: false,
    component: null,
    token: null,
    analyticsPageVisitEvent: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      hasSession: true,
    };
  }

  componentDidMount() {
    const { location: { pathname, search, hash }, isAuthenticated, analyticsPageVisitEvent } = this.props;
    const {
      isAuthorize,
    } = this.getQuery();

    if (!isAuthenticated && isAuthorize) {
      setIntent(AUTHORIZATION_INTENT, { redirectUrl: `${pathname}${search}${hash}` });
    } else {
      analyticsPageVisitEvent && MixPanel.track(analyticsPageVisitEvent, { path: pathname });
    }
  }

  getQuery() {
    const { location: { pathname, search } } = this.props;
    const { registration } = queryString.parse(search) || {};

    return {
      isAuthorize: pathname === '/authorize',
      isRegistration: (Boolean(registration === 'true')),
    };
  }

  render() {
    const {
      isLoading,
      isAuthenticated,
      component: RenderComponent,
      ...rest
    } = this.props;
    const {
      hasSession,
    } = this.state;
    const {
      isAuthorize,
      isRegistration,
    } = this.getQuery();


    return (
      isLoading && !isAuthenticated && hasSession
        ? (loading())
        : (
          <Route
            {...rest} // eslint-disable-line
            render={(props) => (isAuthenticated === true ? (
              <RenderComponent {...rest} {...props} /> // eslint-disable-line
            ) : (
              isAuthorize && isRegistration ? (
                <CreateAccount {...rest} {...props} /> // eslint-disable-line
              ) : (
                <Login {...rest} {...props} /> // eslint-disable-line
              )
            ))}
          />
        )
    );
  }
}

const mapStateToProps = (state) => {
  const { isLoading, isAuthenticated, token } = state.auth;
  return {
    isLoading,
    isAuthenticated,
    token: Cookies.get('auth-token') || token,
  };
};

export default connect(
  mapStateToProps,
  {
    loginUser,
    getUserSession,
  },
)(withRouter(SecureRoute));
