import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const ZenDeskWebWidget = () => {
  const [cookiesUsage, setCookiesUsage] = useState(Cookies.get('cookiesaccepted') === 'yes');
  const [intervalId, setIntervalId] = useState(0);
  const { pathname, search } = useLocation();
  const query = queryString.parse(search) || {};
  const isRegistration = pathname === '/registration';
  const isAssetDetailsTradeInvest = pathname.includes('asset-details') && query.type === 'trade-invest';

  useEffect(() => {
    if (!cookiesUsage) {
      const intervalId = setInterval(() => {
        setCookiesUsage(Cookies.get('cookiesaccepted') === 'yes');
      }, 1000);
      setIntervalId(intervalId);
    }
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if ((isRegistration || pathname.includes('verification/')) && window.zE) {
      window.zE('webWidget', 'hide');
    } else if (window.zE) {
      window.zE('webWidget', 'show');
    }
  }, [pathname]);

  useEffect(() => {
    if (window.zE) {
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          offset: {
            vertical: '60px',
            mobile: { vertical: isAssetDetailsTradeInvest ? '130px' : '60px' },
          },
          position: { horizontal: 'right' },
          zIndex: 1040,
        },
      });
    }
    if (cookiesUsage) clearInterval(intervalId);
  }, [cookiesUsage, isAssetDetailsTradeInvest]);

  return null;
};

export default ZenDeskWebWidget;
