/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import { toast } from 'react-toastify';
import React from 'react';
import LaddaButton from 'react-ladda';
import Translate from '../../../i18n/Translate';
import Toast from '../Toast';

const RefreshButton = () => (
  <LaddaButton
    className="securitize-toast__body__content__refresh-button"
    variant="outline-secondary"
    type="button"
    size="xs"
    onClick={() => location.reload()}
  >
    <Translate translationKey="Texts.refresh" />
  </LaddaButton>
);

export const infoToast = (message, isShowRefreshBtn) => {
  toast.info(
    <Toast>
      <span className="securitize-toast__body__content__message">{message}</span>
      { isShowRefreshBtn && <RefreshButton /> }
    </Toast>, {
      position: 'bottom-center',
      autoClose: 7000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    },
  );
};

export const successToast = (message) => {
  toast.success(
    <Toast
      icon="icon icon-success"
      title="Authentication error"
    >
      {message}
    </Toast>, {
      position: 'bottom-center',
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    },
  );
};

export const errorToast = (message) => {
  toast.error(
    <Toast
      icon="icon icon-error"
      title="Authentication error"
    >
      {message}
    </Toast>, {
      position: 'bottom-center',
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    },
  );
};
