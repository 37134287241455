import Cookies from 'js-cookie';
import { translate } from '../../i18n/Translate';
import { successToast } from '../../components/Toast';
import { confirmTFANext, disableTFANext } from '../../views/Profile/AccountPage/actionTypes';
import { TFA_NOTIFICATION_SUCCESS } from './actionTypes';

const defaultState = {
  tfaNotification: !!Cookies.get('_tfaNotification') && JSON.parse(Cookies.get('_tfaNotification')),
  tfaEverUsed: !!Cookies.get('_tfaEverUsed') && JSON.parse(Cookies.get('_tfaEverUsed')),
};

export const tfa = (state = defaultState, { type, payload }) => {
  switch (type) {
    case confirmTFANext.SUCCESS:
      window.setTimeout(() => successToast(translate('Texts.2fa-enabled-success')), 0);
      Cookies.set('_tfaEverUsed', 'true', { expires: 30, path: '/' });
      return {
        ...state,
        tfaEverUsed: true,
      };

    case disableTFANext.SUCCESS:
      window.setTimeout(() => successToast(translate('Texts.2fa-disabled-success')), 0);
      Cookies.set('_tfaEverUsed', 'true', { expires: 30, path: '/' });
      return {
        ...state,
        tfaEverUsed: true,
      };

    case TFA_NOTIFICATION_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newNotification = state.tfaNotification
        ? [...state.tfaNotification.filter((email) => email !== payload), payload]
        : [payload];
      Cookies.set('_tfaNotification', JSON.stringify(newNotification), { expires: 30, path: '/' });
      return {
        ...state,
        tfaNotification: newNotification,
      };

    default: return state;
  }
};
