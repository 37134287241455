import { useQuery } from '@tanstack/react-query';
import { getImmediateData } from '../../immediate-data';

const queryKey = 'immediate-data';

export const useGetImmediateData = (options = {}) => useQuery(
  [queryKey],
  () => getImmediateData(),
  options,
);
