import Cookies from 'js-cookie';
import {
  CLOSE_MODAL_SUCCESS, NOT_VERIFY_USER_REMIND, OPEN_MODAL_SUCCESS, REDIRECT_FROM_IE,
} from './actionTypes';
import {
  loginNext, logoutNext, sessionNext, VERIFY_EMAIL, VERIFY_EMAIL_ERROR,
} from '../../auth/actionTypes';
import {
  checkPWDNext, confirmTFANext, disableTFANext, getTFASeedNext, deleteAccountNext,
} from '../../views/Profile/AccountPage/actionTypes';
import { setRawIntent } from '../intents';

const defaultState = {
  name: '',
  loading: false,
  isError: false,
  errorType: '',
  redirectFromIE: false,
  notVerifyUserRemind: false,
};

export const modal = (state = defaultState, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL_SUCCESS:
      return {
        ...state,
        isError: payload.name === 'LoginWithTFA' ? false : state.isError,
        errorType: payload.name === 'LoginWithTFA' ? '' : state.errorType,
        name: payload.name,
      };

    case loginNext.INIT:
      return state.name === 'LoginWithTFA'
        ? {
          ...state,
          isError: false,
          errorType: '',
          loading: true,
        } : state;

    case checkPWDNext.INIT:
    case confirmTFANext.INIT:
    case disableTFANext.INIT:
    case deleteAccountNext.INIT:
      return {
        ...state,
        loading: true,
      };

    case getTFASeedNext.SUCCESS:
      return {
        ...defaultState,
        name: 'SetQRTwoFA',
      };

    case REDIRECT_FROM_IE:
      return {
        ...state,
        redirectFromIE: true,
      };

    case CLOSE_MODAL_SUCCESS:
      return {
        ...state,
        name: '',
        isError: false,
        errorType: '',
      };

    case disableTFANext.SUCCESS:
    case confirmTFANext.SUCCESS:
    case logoutNext.SUCCESS:
    case sessionNext.ERROR:
    case VERIFY_EMAIL_ERROR:
    case deleteAccountNext.SUCCESS:
      return defaultState;

    case checkPWDNext.SUCCESS:
      return {
        name: state.name,
        isError: false,
        errorType: '',
        loading: false,
      };

    case loginNext.SUCCESS:
    case VERIFY_EMAIL:
      // eslint-disable-next-line no-case-declarations
      const tfaNotification = !!Cookies.get('_tfaNotification') && JSON.parse(Cookies.get('_tfaNotification'));
      if (payload.user.intent) {
        setRawIntent(payload.user.intent);
      }
      return {
        ...defaultState,
        name:
          (
            (!tfaNotification && !state.redirectFromIE)
            || (Array.isArray(tfaNotification) && !tfaNotification.includes(payload.user.email))
          ) && !payload.user.tfaEnabled ? 'EnableTwoFA' : '',
      };

    case loginNext.ERROR:
      return state.name === 'LoginWithTFA' ? {
        name: state.name,
        isError: true,
        errorType: payload.message,
        loading: false,
      } : state;
    case disableTFANext.ERROR:
    case confirmTFANext.ERROR:
      return {
        name: state.name,
        isError: true,
        errorType: (payload.statusCode === 429) ? payload.message : 'Errors.wrong-2fa-code',
        errorMessage: payload.message,
        loading: false,
      };
    case getTFASeedNext.ERROR:
    case checkPWDNext.ERROR:
    case deleteAccountNext.ERROR:
      return {
        name: state.name,
        isError: true,
        errorType: payload.message,
        loading: false,
      };

    case NOT_VERIFY_USER_REMIND:
      return {
        ...state,
        notVerifyUserRemind: true,
      };

    default:
      return state;
  }
};
