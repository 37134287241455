import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Translate from '../../../../i18n/Translate';

const WizardMenuItem = ({
  path, isComplete, name, nextStep, id,
}) => (
  <NavLink
    id={id}
    to={path}
    className={classNames('wizard-menu-item__link', {
      'wizard-menu-item__link_passed': isComplete,
      'wizard-menu-item__link_next': nextStep && path.split('?')[0].endsWith(`/${nextStep}`),
    })}
    activeClassName="wizard-menu-item__link_active"
  >
    <span className="wizard-menu-item__title">
      <span className="d-none d-lg-inline">
        <Translate translationKey={name} />
      </span>
    </span>
  </NavLink>
);


WizardMenuItem.defaultProps = {
  nextStep: '',
};

WizardMenuItem.propTypes = {
  nextStep: PropTypes.string,
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isComplete: PropTypes.bool.isRequired,
};

export default WizardMenuItem;
