import queryString from 'query-string';

export const doMarketsFlow = (
  {
    search,
    history,
    brokerDealerId,
    suitabilityStatus,
    issuerData,
  },
) => {
  const query = queryString.parse(search) || {};

  if (query.flowType !== 'markets') return false;

  const issuerQueryParams = issuerData ? {
    issuerLogo: issuerData.issuerSquareIcon || '',
    issuerName: issuerData.issuerName,
  } : {};

  const queryParams = {
    issuerId: query.issuerid || query.issuerId,
    redirect: query.redirecturl || query.redirectUrl,
    ...issuerQueryParams,
  };

  const suitabilityUrl = `/profile/suitability?${queryString.stringify(queryParams)}`;

  if (!brokerDealerId) {
    history.push(`/market/share-info?redirectUrl=${encodeURIComponent(suitabilityUrl)}`);
    return true;
  }
  if (suitabilityStatus === 'none') {
    history.push(suitabilityUrl);
    return true;
  }

  return false;
};
