import React from 'react';

import { CookiesPolicy } from './components/CookiesUsage';
import ErrorPage from './views/SystemPages/ErrorPage';

const VerifyEmail = React.lazy(() => import('./views/SystemPages/Registration/VerifyEmail/VerifyEmail'));
const Expired = React.lazy(() => import('./views/SystemPages/Registration/Expired/Expired'));
const CreateAccount = React.lazy(() => import('./views/SystemPages/Registration/CreateAccount/CreateAccount'));
const CreateAccountByReference = React.lazy(() => import(
  './views/SystemPages/Registration/CreateAccountByReference/CreateAccountByReference'
));
const ValidateReferredLink = React.lazy(() => import(
  './views/SystemPages/Registration/CreateAccountByReference/components/ValidateReferredLink/ValidateReferredLink'
));
const PrivacyPolicy = React.lazy(() => import('./views/SystemPages/Registration/PrivacyPolicy/PrivacyPolicy'));
const TermsConditions = React.lazy(() => import('./views/SystemPages/Registration/TermsConditions/TermsConditions'));
const NotAllowed = React.lazy(() => import('./views/SystemPages/Registration/NotAllowed/NotAllowed'));
const Profile = React.lazy(() => import('./views/Profile/Profile'));
const Login = React.lazy(() => import('./views/SystemPages/Login'));
const PageNotFound = React.lazy(() => import('./views/SystemPages/PageNotFound'));
const ResetTFA = React.lazy(() => import('./views/ResetTFA'));
const RequestResetPass = React.lazy(() => import('./views/SystemPages/RequestResetPass'));
const Authorize = React.lazy(() => import('./views/Authorize/Authorize'));
const RequestResetPassInfo = React.lazy(() => import('./views/SystemPages/RequestResetPass/ResetPassInfo/ResetPassInfo'));
const VerifyResetPassCode = React.lazy(() => import('./views/SystemPages/VerifyResetPassCode/VerifyResetPassCode'));
const ResetPassword = React.lazy(() => import('./views/SystemPages/SetNewPassword/SetNewPassword'));
const Settings = React.lazy(() => import('./views/Settings'));
const WalletsPage = React.lazy(() => import('./views/WalletsPage'));
const HoldingPage = React.lazy(() => import('./views/HoldingPage'));
const PrimaryMarket = React.lazy(() => import('./views/PrimaryMarket'));
const ATS = React.lazy(() => import('./views/ATS'));
const AcceptInvitePage = React.lazy(() => import('./views/SystemPages/AcceptInvitePage/AcceptInvitePage'));
const SecMarketShareInfo = React.lazy(() => import('./views/SystemPages/SecMarketShareInfo'));
const ImpotInformation = React.lazy(() => import('./views/SystemPages/ImportInformation'));
const Market = React.lazy(() => import('./views/Market'));
const Account = React.lazy(() => import('./views/Account'));
const Redemption = React.lazy(() => import('./views/Redemption'));
const AssetDetails = React.lazy(() => import('./views/Investments/components/AssetDetails/AssetDetails'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config

const ENABLE_PRIMARY_MARKET_MFE = process.env.REACT_APP_ENABLE_PRIMARY_MARKET_MFE === 'true';
const ENABLE_PRIMARY_MARKET_DOCUSIGN_REDIRECT = process.env.REACT_APP_ENABLE_PRIMARY_MARKET_DOCUSIGN_REDIRECT === 'true';

const routes = [
  {
    path: '/cookies-policy',
    name: 'Cookies Policy',
    secure: false,
    component: CookiesPolicy,
  },
  {
    path: '/',
    exact: true,
    name: 'Home',
  },
  {
    secure: false,
    path: '/registration/expired',
    name: 'Expired',
    component: Expired,
  },
  {
    secure: false,
    path: '/registration/verify-email',
    name: 'VerifyEmail',
    component: VerifyEmail,
  },
  {
    secure: false,
    path: '/registration/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    secure: false,
    path: '/registration/terms-conditions',
    name: 'TermsConditions',
    component: TermsConditions,
  },
  {
    secure: false,
    path: '/registration/not-allowed',
    name: 'NotAllowed',
    component: NotAllowed,
  },
  {
    secure: false,
    path: '/registration/by-reference',
    name: 'Registration by reference',
    component: CreateAccountByReference,
  },
  {
    secure: false,
    path: '/pst/:uuid',
    name: 'Validate referred link',
    component: ValidateReferredLink,
  },
  {
    secure: false,
    path: '/registration',
    name: 'Registration',
    component: CreateAccount,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/authorize',
    name: 'Authorize Access',
    component: Authorize,
  },
  {
    path: '/login',
    exact: true,
    name: 'Login',
    component: Login,
  },
  {
    path: '/reset-password',
    name: 'Request reset password',
    component: RequestResetPass,
    secure: false,
    exact: true,
  },
  {
    secure: false,
    path: '/reset-password/info',
    name: 'Request reset password info',
    component: RequestResetPassInfo,
    exact: true,
  },
  {
    path: '/verify-reset-password',
    secure: false,
    name: 'Verify reset password code',
    component: VerifyResetPassCode,
  },
  {
    path: '/reset-password/new-password',
    secure: false,
    name: 'Reset password',
    component: ResetPassword,
    exact: true,
  },
  {
    path: '/wallets',
    exact: true,
    name: 'Wallets',
    component: WalletsPage,
  },
  {
    path: '/settings',
    exact: true,
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/import',
    exact: true,
    name: 'Import',
    component: ImpotInformation,
  },
  {
    path: '/portfolio',
    exact: false,
    name: 'Holdings',
    component: HoldingPage,
  },
  {
    path: '/asset-details/:tokenId',
    name: 'Asset Details',
    secure: true,
    exact: true,
    component: AssetDetails,
  },
  {
    path: '/market/share-info',
    exact: true,
    name: 'Market',
    component: SecMarketShareInfo,
  },
  // TODO remove this route once primary market MFE is activated
  ...(ENABLE_PRIMARY_MARKET_DOCUSIGN_REDIRECT ? [{
    path: '/primary-market/docusign-redirect',
    exact: true,
    secure: false,
    name: 'Market',
    component: PrimaryMarket,
  }] : []),
  {
    path: ENABLE_PRIMARY_MARKET_MFE ? ['/primary-market', '/market'] : '/market',
    exact: !ENABLE_PRIMARY_MARKET_MFE,
    name: 'Primary Market',
    component: ENABLE_PRIMARY_MARKET_MFE ? PrimaryMarket : Market,
    secure: !ENABLE_PRIMARY_MARKET_MFE,
  },
  {
    path: '/secondary-market',
    exact: false,
    name: 'Secondary Market',
    component: ATS,
    secure: true,
  },
  {
    path: '/account',
    exact: false,
    name: 'Account',
    component: Account,
  },
  {
    path: '/redemption',
    exact: false,
    name: 'Redemptions',
    component: Redemption,
  },
  {
    path: '/404',
    exact: true,
    secure: false,
    name: '',
    component: PageNotFound,
  },
  {
    path: '/invite',
    exact: true,
    secure: false,
    name: '',
    component: AcceptInvitePage,
  },
  {
    path: '/reset-tfa',
    exact: false,
    secure: false,
    name: '',
    component: ResetTFA,
  },
  {
    path: '/error',
    exact: true,
    name: 'Error',
    component: ErrorPage,
  },
];

export default routes;
