import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import ReactComment from '../../components/ReactComment';

export const getTranslation = (translations = {}, translationKey = '-', parameters = {}) => {
  const translationKeys = translationKey.split(' ');
  return translationKeys.map(
    (translationKey) => Object.keys(parameters).reduce(
      (translation, key) => translation.replace(new RegExp(`{${key}}`, 'g'), parameters[key]),
      translations[translationKey] || translationKey,
    ),
  ).join(' ');
};
// dont make one liner, \n doesnt work as new line with white-space:pre, this does
const newLine = `
`;
const replaceWithNewLine = (string) => string.replace(/\\n|<br>|<br\/>>|<br \/>/g, newLine);

const markupBold = (translation) => {
  const parts = translation.split(/<b>|<\/b>|<\/ b>/);
  if (parts.length > 1) {
    return parts.reduce(
      (res, part, index) => {
        if (index % 2) {
          res.push(<strong key={`part-${res.length}`}>{replaceWithNewLine(part)}</strong>);
        } else {
          res.push(replaceWithNewLine(part));
        }
        return res;
      }, [],
    );
  }
  return replaceWithNewLine(translation);
};

const Translate = ({ translationKey, translations, parameters }) => (
  translationKey ? (
    <>
      {/* {
        process.env.NODE_ENV === 'development' && <ReactComment text={translationKey} />
      } */}
      {
        markupBold(
          getTranslation(translations, translationKey, parameters),
        )
      }
    </>
  ) : null
);

Translate.propTypes = {
  translationKey: PropTypes.string,
  parameters: PropTypes.objectOf(PropTypes.any),
  translations: PropTypes.objectOf(PropTypes.any),
};

Translate.defaultProps = {
  translationKey: '',
  parameters: {},
  translations: {},
};

const mapStateToProps = ({ i18n: { translations } }) => ({ translations });

export default connect(mapStateToProps, {})(Translate);
