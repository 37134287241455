import React from 'react';
import superagent from 'superagent';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import appendQuery from 'append-query';
import Toast from '../components/Toast/Toast';
import Next from './Next';
// import { getStore } from '../store';
import store from '../store';

let suspendErrors = false;
window.addEventListener('beforeunload', () => {
  suspendErrors = true;
});

const defaultResponse = {
  status: 0, success: false, error: 'Failed to fetch', data: null,
};

const errorFunctions = [];

const showError = (message) => {
  !suspendErrors
  && toast.error(
    <Toast
      icon="icon icon-error"
      title="Authentication error"
    >
      {message}
    </Toast>, {
      position: 'bottom-center',
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    },
  );
};

const dispatchActions = (actions) => {
  // const store = getStore();
  const actionsList = Array.isArray(actions) ? actions : [actions];
  actionsList.forEach(
    (action) => {
      if (action) {
        store.dispatch(
          typeof action === 'function' ? action() : action,
        );
      }
    },
  );
};

export const onErrorMiddleware = (func) => {
  if (typeof func === 'function') {
    errorFunctions.push(func);
  } else {
    throw new Error('On Error middleware must be a function!');
  }
};

const getSuperAgent = (method = '') => {
  switch (method.toUpperCase()) {
    case 'GET':
      return superagent.get;
    case 'POST':
      return superagent.post;
    case 'PUT':
      return superagent.put;
    case 'PATCH':
      return superagent.patch;
    case 'DELETE':
      return superagent.delete;
    default:
      return superagent.get;
  }
};

export const createHttpAction = (next, jsonData = {}, options = {}) => {
  if (!(next instanceof Next) || !options) {
    throw new Error('Invalid Next Parameter!');
  }
  // const store = getStore();
  const combinedOptions = {
    ...next.options,
    ...options,
  };
  const state = store.getState();
  const params = jsonData.params || {};
  if (params) {
    delete jsonData.params;
    Object.keys(params).forEach(
      (key) => {
        combinedOptions.uri = combinedOptions.uri.replace(
          new RegExp(`{${key}}`, 'g'), params[key],
        );
      },
    );
    const { query } = jsonData;
    if (query) {
      combinedOptions.uri = appendQuery(combinedOptions.uri, query, { encodeComponents: false });
    }
  }
  const request = getSuperAgent(combinedOptions.method);
  const { uri } = combinedOptions;
  const current = request(uri).withCredentials();

  current.set('Authorization', Cookies.get('auth-token') || state.auth.state);
  current.set('Accept', 'application/json');
  if (jsonData.files && jsonData.files.length) {
    jsonData.files.forEach(
      ({ name, file }) => {
        current.attach(name, file);
      },
    );
    if (jsonData.body) {
      console.warn('Super agent forbids sending files with JSON data');
    }
  } else {
    current.send(jsonData.body);
  }

  current.then(
    (response) => {
      const data = (response && response.body) || defaultResponse;
      store.dispatch({
        type: next.SUCCESS,
        payload: data,
      });
      dispatchActions(options.onSuccess && options.onSuccess(data));
      dispatchActions(options.onFinish && options.onFinish(null, data));
    },
  )
    .catch(
      (error) => {
        const { response = {}, message = 'Something went wrong, Please try again or contact support.', status } = error;
        const data = (response && response.body) || defaultResponse;
        store.dispatch({
          type: next.ERROR,
          payload: data,
        });
        if (!status || status === 500) {
          showError(message);
        }
        dispatchActions(options.onError && options.onError(data));
        dispatchActions(options.onFinish && options.onFinish(data, null));
        errorFunctions.forEach(
          (errorFunction) => errorFunction(next, dispatchActions, data, error),
        );
      },
    );

  return {
    action: {
      type: next.INIT,
    },
    request: current,
  };
};
