import React from 'react';
import PropTypes from 'prop-types';
import Translate from '../../i18n/Translate';

const ServerErrorPage = ({ children, errorText }) => (
  <div>
    <Translate translationKey={errorText} />
    {children}
  </div>
);

ServerErrorPage.propTypes = {
  children: PropTypes.node,
  errorText: PropTypes.string,
};
ServerErrorPage.defaultProps = {
  children: [],
  errorText: 'Errors.server-error',
};


export default ServerErrorPage;
