import { GET_ACCREDITATION_NEXT, SUBMIT_ACCREDITATION_NEXT } from './actionTypes';

const defaultState = {
  isLoading: true,
  isError: false,
  isInitialized: false,
  data: {},
};

export const accreditation = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_ACCREDITATION_NEXT.INIT:
      return {
        ...state,
        isInitialized: true,
        isLoading: true,
        isError: false,
      };
    case GET_ACCREDITATION_NEXT.ERROR:
    case SUBMIT_ACCREDITATION_NEXT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case GET_ACCREDITATION_NEXT.SUCCESS:
    case SUBMIT_ACCREDITATION_NEXT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: payload,
      };

    default:
      return state;
  }
};
