import { REDIRECT_URL } from './actionTypes';

const defaultState = {
  redirectUrl: null,
};

export const redirectUrl = (state = defaultState, { type, payload }) => {
  switch (type) {
    case REDIRECT_URL:
      return {
        ...state,
        redirectUrl: payload,
      };

    default: return state;
  }
};

