
export const OPEN_MODAL_SUCCESS = 'OPEN_MODAL_SUCCESS';
export const CLOSE_MODAL_SUCCESS = 'CLOSE_MODAL_SUCCESS';

export const TFA_NOTIFICATION_SUCCESS = 'TFA_NOTIFICATION_SUCCESS';

export const SAVE_MODAL_OPTIONS = 'SAVE_MODAL_OPTIONS';
export const REDIRECT_FROM_IE = 'REDIRECT_FROM_IE';

export const NOT_VERIFY_USER_REMIND = 'NOT_VERIFY_USER_REMIND';

export const REDIRECT_WARNING_DISABLED = 'REDIRECT_WARNING_DISABLED';

export const REVERSE_SOLICITATION_MODAL_DISABLED = 'REVERSE_SOLICITATION_MODAL_DISABLED';

export const REDIRECT_URL = 'REDIRECT_URL';
