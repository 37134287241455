// TODO alex
import { MixPanel as _MixPanel } from './mixPanelUiKit';

const SUPER_PROPERTIES = [
  'verificationStatus',
  'accreditationStatus',
  'suitabilityStatus',
  'accountStatus',
  'hasEscrowAccount',
  'hasLinkedAccount',
];
const UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

const getUTMData = () => {
  const query = new URLSearchParams(window.location.href.split('?')[1]);
  return UTM_PARAMS.filter((item) => query.has(item)).reduce((acc, item) => ({ [item]: query.get(item), ...acc }), {});
};

const getRelevantSuperProperties = (userData) => Object.keys(userData)
  .filter((key) => SUPER_PROPERTIES.includes(key))
  .reduce((acc, item) => ({ [item]: userData[item], ...acc }), {});


export const MixPanel = {
  alias: (investorId) => {
    _MixPanel.alias(investorId);
  },
  identify: (userData) => {
    _MixPanel.identify(userData.mixPanelId);
    const superProperties = getRelevantSuperProperties(userData);
    if (Object.keys(superProperties).length > 0) {
      _MixPanel.register(superProperties);
    }
  },
  register: (userData) => {
    const superProperties = getRelevantSuperProperties(userData);
    if (Object.keys(superProperties).length > 0) {
      _MixPanel.register(superProperties);
    }
  },
  track: (event, data = {}) => {
    _MixPanel.track(event, data);
    const utmData = getUTMData();
    if (Object.keys(utmData).length > 0) {
      _MixPanel.register(utmData);
    }
  },
  set_once: (data) => {
    _MixPanel.people.set_once({
      investorId: data,
    });
  },
  getMixpanelId: () => _MixPanel.getMixpanelId(),
  resetMixPanel: () => {
    _MixPanel.resetMixPanel();
  },
};
