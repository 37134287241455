import { getEntityInfoNext } from './actionTypes';
import { updateEntityInfoNext } from '../../verificationProfile/EntityInfo/actionTypes';

const defaultState = {
  isInitialized: false,
  isLoading: false,
  isError: false,
  data: {},
};

export const entityInfo = (state = defaultState, { type, payload }) => {
  switch (type) {
    case getEntityInfoNext.INIT:
      return {
        ...state,
        isLoading: true,
      };

    case getEntityInfoNext.SUCCESS:
    case updateEntityInfoNext.SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        isInitialized: true,
      };

    case getEntityInfoNext.ERROR:
      return {
        ...state,
        isInitialized: false,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
};
