import { combineReducers } from 'redux';
import { updateAddressInfoNext } from './AddressInfo/actionTypes';
import { updateInvestorTypeNext } from './InvestorType/actionTypes';
import { currentSigner } from './LegalSigners/reducer';


const defaultState = {
  isLoading: false,
  errorType: '',
  isError: false,
};

const wizard = (state = defaultState, { type, payload }) => {
  switch (type) {
    case updateInvestorTypeNext.INIT:
      return {
        ...state,
        isLoading: true,
      };

    case updateInvestorTypeNext.SUCCESS:
    case updateAddressInfoNext.SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorType: '',
        isError: false,
      };
    case updateInvestorTypeNext.ERROR:
    case updateAddressInfoNext.ERROR:
      return {
        ...state,
        isLoading: false,
        errorType: payload.message || 'server error',
        isError: true,
      };

    default:
      return state;
  }
};

export const verification = combineReducers({
  wizard,
  currentSigner,
});
