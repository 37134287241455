import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const ReKyc = React.lazy(() => import('./ReKyc'));
const SignerEntityDocuments = React.lazy(() => import('./LegalSigners/SignerEntityDocuments'));
const EntityAddressInfo = React.lazy(() => import('./AddressInfo/EntityAddressInfo'));
const IndividualAddressInfo = React.lazy(() => import('./AddressInfo/IndividualAddressInfo'));
const SignerAddressInfo = React.lazy(() => import('./LegalSigners/LegalSignerAddress'));
const IdentityVerification = React.lazy(() => import('./SumSubIdentityVerification'));
const StatusCheck = React.lazy(() => import('./StatusCheck'));
const LegalSingersTypeSelect = React.lazy(() => import('./LegalSigners/LegalSingersTypeSelect'));
const LegalSignersCheck = React.lazy(() => import('./LegalSigners/LegalSignersCheck'));
const LegalInformation = React.lazy(() => import('./LegalInformation'));
const IndividualSignerDocuments = React.lazy(() => import('./LegalSigners/IndividualSignerDocuments'));
const LivenessCheck = React.lazy(() => import('./LivenessCheck'));

const EntityInfo = React.lazy(() => import('./EntityInfo'));
const EntityDocuments = React.lazy(() => import('./EntityDocuments'));


const reKyc = {
  path: '/profile/verification/resubmit',
  name: undefined,
  analyticsPageVisitEvent: 're-kyc-visit',
  component: ReKyc,
  exact: true,
  isComplete: false,
};

const stepsOrder = {
  individual: [
    'identity-verification',
    'liveness',
    'address-info',
    'legal-information',
    'check',
  ],
  entity: [
    'entity-information',
    'address-info',
    'entity-documents',
    'legal-signers',
    'check',
  ],
};

const KYCRoutes = [
  {
    path: '/profile/verification/identity-verification',
    name: 'Texts.identity-verification',
    analyticsPageVisitEvent: 'kyc-identity-verification-visit',
    component: IdentityVerification,
    exact: true,
    isComplete: false,
    pageNumber: 3,
  },
  {
    path: '/profile/verification/liveness',
    name: 'Texts.liveness-check',
    analyticsPageVisitEvent: 'liveness',
    component: LivenessCheck,
    exact: true,
    isComplete: false,
    pageNumber: 4,
  },
  {
    path: '/profile/verification/address-info',
    name: 'Texts.address-and-phone-info',
    analyticsPageVisitEvent: 'kyc-address-info-visit',
    component: IndividualAddressInfo,
    exact: true,
    isComplete: false,
    pageNumber: 5,
  },
  {
    path: '/profile/verification/legal-information',
    name: 'Texts.legal-information',
    analyticsPageVisitEvent: 'kyc-legal-info-visit',
    component: LegalInformation,
    exact: true,
    isComplete: false,
    pageNumber: 6,
  },
];

const LegalSignersIndividualRoutes = [
  {
    path: '/profile/verification/key-parties/individual-address',
    name: 'Texts.legal-signers-route-address',
    component: SignerAddressInfo,
    analyticsPageVisitEvent: 'kyb-signers-individual-address-visit',
    exact: true,
    isComplete: false,
    pageNumber: 5.1,
  },
  {
    path: '/profile/verification/key-parties/individual-documents',
    name: 'Texts.legal-signers-identity-documents',
    component: IndividualSignerDocuments,
    analyticsPageVisitEvent: 'kyb-signers-individual-docs-visit',
    exact: true,
    isComplete: false,
    pageNumber: 5.2,
  },
  {
    path: '/profile/verification/key-parties/individual-check',
    name: 'Texts.legal-signers-check',
    analyticsPageVisitEvent: 'kyb-signers-individual-check-visit',
    component: LegalSignersCheck,
    exact: true,
    isComplete: false,
    pageNumber: 5.3,
  },
];

const LegalSignersEntityRoutes = [
  {
    path: '/profile/verification/key-parties/entity-documents',
    name: 'Texts.legal-documents',
    analyticsPageVisitEvent: 'kyb-signers-entity-docs-visit',
    component: SignerEntityDocuments,
    exact: true,
    isComplete: false,
    pageNumber: 5.2,
  },
  {
    path: '/profile/verification/key-parties/check',
    name: 'Texts.check',
    analyticsPageVisitEvent: 'kyb-signers-entity-check-visit',
    component: LegalSignersCheck,
    exact: true,
    isComplete: false,
    pageNumber: 5.3,
  },
];

const KYBRoutes = [
  {
    path: '/profile/verification/entity-information',
    name: 'Texts.entity-info',
    component: EntityInfo,
    analyticsPageVisitEvent: 'kyb-entity-info-visit',
    exact: true,
    isComplete: false,
    pageNumber: 2,
  },
  {
    path: '/profile/verification/address-info',
    name: 'Texts.address',
    component: EntityAddressInfo,
    analyticsPageVisitEvent: 'kyb-address-info-visit',
    exact: true,
    isComplete: false,
    pageNumber: 3,
  },
  {
    path: '/profile/verification/entity-documents',
    name: 'Texts.entity-documents',
    analyticsPageVisitEvent: 'kyb-entity-docs-visit',
    component: EntityDocuments,
    exact: true,
    isComplete: false,
    pageNumber: 4,
  },
  {
    path: '/profile/verification/key-parties',
    name: 'Texts.legal-signers',
    analyticsPageVisitEvent: 'kyb-legal-signers-visit',
    component: LegalSingersTypeSelect,
    exact: true,
    isComplete: false,
    pageNumber: 5,
    childRoutes: (type) => (type === 'individual' ? LegalSignersIndividualRoutes : LegalSignersEntityRoutes),
  },
  {
    path: '/profile/verification/check',
    name: 'Texts.check',
    analyticsPageVisitEvent: 'kyb-check-visit',
    component: StatusCheck,
    exact: true,
    isComplete: false,
    pageNumber: 6,
  },
];

const getKYBRoutes = (isExtended) => (!isExtended ? KYBRoutes : [
  ...KYBRoutes,
  ...LegalSignersEntityRoutes,
  ...LegalSignersIndividualRoutes,
]);

export const useVerificationRoutes = () => {
  const { user } = useSelector(({ auth }) => auth || {});
  const { investorType } = user || {};
  const location = useLocation();
  const getVerificationRoutes = (isExtended) => {
    const addQueryParamsToRoutes = (routs) => routs.map((route) => ({
      ...route,
      path: `${route?.path.split('?')[0]}${location.search}`,
    }));


    switch (investorType) {
      case 'entity':
        return addQueryParamsToRoutes(getKYBRoutes(isExtended));
      case 'individual':
        if (['documents-expired', 'updates-required'].includes(user.verificationStatus)) {
          return addQueryParamsToRoutes([...KYCRoutes, reKyc]);
        }
        return addQueryParamsToRoutes(KYCRoutes);
      default:
        return addQueryParamsToRoutes(KYCRoutes);
    }
  };
  const getStepsOrder = () => {
    switch (investorType) {
      case 'entity':
        return stepsOrder.entity;
      case 'individual':
        return stepsOrder.individual;
      default:
        return KYCRoutes;
    }
  };

  return { getVerificationRoutes, getStepsOrder };
};
