import React, { Component } from 'react';
import { Button, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Translate, { translate } from '../../i18n/Translate';
import './Password.scss';

class PasswordInput extends Component {
  static propTypes = {
    translationKey: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    invalid: PropTypes.bool,
    required: PropTypes.bool,
    onBlur: PropTypes.func,
    autoComplete: PropTypes.string,
    id: PropTypes.string,
  };

  static defaultProps = {
    translationKey: '',
    placeholder: 'Placeholders.password',
    invalid: false,
    required: false,
    autoComplete: '',
    id: '',
    onBlur: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      isShownPass: false,
    };
  }

  handleShowPass = () => {
    this.setState(({ isShownPass }) => ({ isShownPass: !isShownPass }));
  };

  render() {
    const {
      id,
      translationKey,
      value,
      onChange,
      name,
      placeholder,
      invalid,
      required,
      onBlur,
      autoComplete,
    } = this.props;

    const { isShownPass } = this.state;

    return (
      <div className="securitize-password">
        {
          translationKey && (
            <Label htmlFor={name}>
              <Translate translationKey={translationKey} />
            </Label>
          )
        }
        <div className={classNames('securitize-password__buttonInside d-flex', {
          'border-danger': invalid,
        })}
        >
          <Input
            type={isShownPass ? 'text' : 'password'}
            name={name}
            className="px-3 py-4 securitize-password__inputBlock border-0"
            id={id || name}
            value={value}
            onChange={onChange}
            placeholder={translate(placeholder)}
            required={required}
            autoComplete={autoComplete}
            onBlur={onBlur}
          />
          <Button
            id={`${id || name}-toggle-view`}
            color="link"
            onClick={this.handleShowPass}
          >
            <i className={`text-muted icon-icon-eye-${isShownPass ? 'hide' : 'show'}`} />
          </Button>
        </div>
      </div>
    );
  }
}

export default PasswordInput;
