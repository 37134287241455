import { verifyResetPassCodeNext } from './actionTypes';

const defaultState = {
  errorType: '',
  isLoading: false,
  isError: false,
  data: {},
};

export const verifyResetPassCode = (state = defaultState, { type, payload }) => {
  switch (type) {
    case verifyResetPassCodeNext.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorType: '',
      };

    case verifyResetPassCodeNext.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorType: payload.message || 'server error',
      };

    case verifyResetPassCodeNext.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: payload.data || {},
      };

    default:
      return state;
  }
};
