import React from 'react';
import PropTypes from 'prop-types';
import './SidebarNav.scss';
import classnames from 'classnames';
import { AppSidebarNav } from '@coreui/react';

const SidebarNav = (props) => {
  const { children, isWizard } = props;
  return (
    <AppSidebarNav id="sec-id-main-menu" className={classnames(isWizard ? 'wizard-menu' : 'sec-id-main-menu')}>
      {children}
    </AppSidebarNav>
  );
};

SidebarNav.defaultProps = {
  children: null,
  isWizard: false,
};

SidebarNav.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  isWizard: PropTypes.bool,
};

export default SidebarNav;
