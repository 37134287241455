import { FETCH_IMPORT_INFORMATION } from './actionTypes';

const defaultState = {
  isLoading: false,
  isError: false,
  data: {},
};

export const importInformation = (state = defaultState, { type, payload }) => {
  switch (type) {
    case FETCH_IMPORT_INFORMATION.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case FETCH_IMPORT_INFORMATION.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case FETCH_IMPORT_INFORMATION.SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        isError: false,
      };

    default:
      return state;
  }
};
