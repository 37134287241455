// eslint-disable-next-line no-unused-vars
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useMarketsOrOfferingsRedirect = () => {
  const history = useHistory();
  const { brokerDealerId } = useSelector(({ auth }) => auth.user || {});

  const handleRedirect = (path) => {
    const redirect = () => {
      const isUrl = path.startsWith('http://') || path.startsWith('https://');
      if (!brokerDealerId) {
        const pathData = { pathname: '/market/share-info' };
        if (isUrl) {
          pathData.search = `?${new URLSearchParams({ redirectUrl: path }).toString()}`;
        }
        history.push(pathData);
        return;
      }
      isUrl ? window.location.href = path : history.push(path);
    };

    redirect();
  };

  return { handleRedirect };
};

export default useMarketsOrOfferingsRedirect;
