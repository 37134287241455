import React from 'react';
import PropTypes from 'prop-types';
import ServerError from '../ServerError';
import ServerErrorPage from '../ServerErrorPage';

const propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  errorText: PropTypes.string,
  children: PropTypes.node,
  errorDisplay: PropTypes.node,
  isTranslationError: PropTypes.bool,
};

const defaultProps = {
  isLoading: true,
  isError: false,
  isTranslationError: false,
  errorText: 'Errors.server-error',
  children: [],
  errorDisplay: [],
};

const SecuritizePage = ({
  isLoading, isError, errorText, children, errorDisplay, isTranslationError,
}) => (
  <>
    {
      isTranslationError
        ? <ServerErrorPage />
        : isError ? (
          <ServerError errorText={errorText}>
            { errorDisplay }
          </ServerError>
        ) : (
          isLoading ? (
            <div className="loader animated fadeIn pt-1 text-center">
              <div className="sk-spinner sk-spinner-pulse" />
            </div>
          ) : (
            children
          )
        )
    }
  </>
);

SecuritizePage.propTypes = propTypes;
SecuritizePage.defaultProps = defaultProps;


export default SecuritizePage;
