import Next from '../../../http/Next';

export const getAuthorizeAccountInvitationsNext = new Next(
  '/user/authorized-investors', 'GET_AUTHORIZE_ACOUNTS', { method: 'GET' },
);

export const sendAuthorizeAccountInvitationsNext = new Next(
  '/user/authorized-investors', 'SEND_AUTHORIZE_ACOUNT_INVITE', { method: 'POST' },
);

export const changeAuthorizedAccountRoleNext = new Next(
  '/user/authorized-investors/role', 'CHANGE_AUTHORIZED_ACOUNT_ROLE', { method: 'POST' },
);

export const approveAuthorizeAccountRequestNext = new Next(
  '/user/authorized-investors/approve-request', 'SEND_APPROVE_AUTHORIZE_ACOUNT_REQUEST', { method: 'POST' },
);

export const deleteAuthorizeAccountsNext = new Next(
  '/user/authorized-investors', 'DELETE_AUTHORIZE_ACOUNT', { method: 'DELETE' },
);

