import React, { useState } from 'react';
import {
  Button, FormGroup, Input, Label, ModalFooter,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Translate from '../../i18n/Translate';
import recommend2FA from '../../assets/img/other/second_factor_enable.svg';
import ModalComponent from './ModalComponent';
import { disableTFANotification, showModal } from '../../utils/helpers/actions';

const EnableTwoFAModal = ({
  onToggle, isOpen, disableTFANotification, showModal, email,
}) => {
  const [check, onChangeCheck] = useState(false);
  const onEnable = () => {
    if (check) disableTFANotification(email);
    showModal('TFAPassword');
  };

  const onCancel = () => {
    if (check) disableTFANotification(email);
    onToggle();
  };

  const toggle = () => {
    if (check) disableTFANotification(email);
    onToggle();
  };

  return (
    <ModalComponent
      title="2fa.title"
      image={recommend2FA}
      isOpen={isOpen}
      onToggle={toggle}
      name="EnableTwoFA"
    >
      <p className="modal-text font-16 m-0 text-center">
        <Translate translationKey="2fa.description" />
      </p>
      <FormGroup check className="checkbox d-flex align-items-center justify-content-center mt-4 pt-2 p-0">
        <Input
          id="twofa-dont-show"
          className="form-checkbox"
          type="checkbox"
          name="checkbox1"
          value={check}
          onChange={() => onChangeCheck(!check)}
        />
        <Label check className="form-check-label font-14 subtext" htmlFor="twofa-dont-show">
          <Translate translationKey="Texts.dont-show-again" />
        </Label>
      </FormGroup>
      <ModalFooter className="border-0 p-0 flex-column">
        <Button
          id="twofa-enable"
          className="m-0"
          color="primary"
          size="lg"
          block
          onClick={onEnable}
        >
          <Translate translationKey="Texts.enable" />
        </Button>
        <Button
          id="twofa-later"
          className="m-0 mt-1"
          size="lg"
          color="link"
          block
          onClick={onCancel}
        >
          <Translate translationKey="Texts.do-later" />
        </Button>
      </ModalFooter>
    </ModalComponent>
  );
};

EnableTwoFAModal.propTypes = {
  onToggle: PropTypes.func.isRequired,
  disableTFANotification: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
};

const mapStateToProps = ({ auth: { user } }) => ({
  email: user.email || '',
});

export default connect(
  mapStateToProps,
  {
    disableTFANotification,
    showModal,
  },
)(EnableTwoFAModal);
