// import 'react-app-polyfill/ie9'; // For IE 9-11 support
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/ie11'; // For  IE 11 support
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/stable';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
