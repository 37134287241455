import React, { Component } from 'react';
import {
  DropdownToggle, DropdownMenu, DropdownItem, Dropdown,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslations } from '../../i18n/actions';
import './LanguageSelect.scss';

class LanguageSelect extends Component {
  static propTypes = {
    getTranslations: PropTypes.func.isRequired,
    activeLanguage: PropTypes.string,
    languages: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    activeLanguage: 'EN',
    languages: { EN: 'English' },
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  onLanguageSelected(language) {
    const { getTranslations } = this.props;
    getTranslations(language);
  }

  render() {
    const {
      open,
    } = this.state;
    const {
      activeLanguage,
      languages,
    } = this.props;

    return (
      <Dropdown
        id="language-select"
        size="lg"
        className="language-select"
        isOpen={open}
        toggle={() => { this.setState(({ open }) => ({ open: !open })); }}
      >
        <DropdownToggle caret id="language-toggle">
          {activeLanguage.toUpperCase()}
          <i className="icon-point-down font-20 ml-1" />
        </DropdownToggle>
        <DropdownMenu right>
          {
            Object.keys(languages).map(
              (language) => (
                <DropdownItem
                  id={`language-item-${language}`}
                  onClick={() => this.onLanguageSelected(language)}
                  key={language}
                >
                  {languages[language].title}
                </DropdownItem>
              ),
            )
          }
        </DropdownMenu>
      </Dropdown>
    );
  }
}


const mapStateToProps = ({ i18n }) => ({
  languages: i18n.languages,
  activeLanguage: i18n.activeLanguage,
});

export default connect(
  mapStateToProps,
  {
    getTranslations,
  },
)(LanguageSelect);
