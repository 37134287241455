import Cookies from 'js-cookie';
import { REVERSE_SOLICITATION_MODAL_DISABLED } from './actionTypes';

const defaultState = {
  reverseSolicitationDisabled: JSON.parse(Cookies.get('_reverseSolicitationDisabled') || '[]'),
};

export const marketReverseSolicitationModal = (state = defaultState, { type, payload }) => {
  switch (type) {
    case REVERSE_SOLICITATION_MODAL_DISABLED:
      // eslint-disable-next-line no-case-declarations
      const reverseSolicitationUsers = state.reverseSolicitationDisabled
        ? [...state.reverseSolicitationDisabled.filter((email) => email !== payload), payload]
        : [payload];
      Cookies.set('_reverseSolicitationDisabled', JSON.stringify(reverseSolicitationUsers), { expires: 3600, path: '/' });
      return {
        ...state,
        reverseSolicitationDisabled: reverseSolicitationUsers,
      };

    default: return state;
  }
};

