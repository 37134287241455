import {
  getPromotionsNext,
} from './actionTypes';

const defaultState = {
  isInitialized: false,
  isLoading: false,
  isError: false,
  data: [],
};

export const promotions = (state = defaultState, { type, payload }) => {
  switch (type) {
    case getPromotionsNext.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isInitialized: true,
      };
    case getPromotionsNext.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: [
          ...state.data,
          ...payload,
        ],
      };
    case getPromotionsNext.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isInitialized: false,
      };
    default:
      return state;
  }
};
