import { translate } from '../../../i18n/Translate';
import { successToast, errorToast } from '../../../components/Toast';
import { requestResetPassNext, SAVE_TEMP_DATA } from './actionTypes';

const defaultState = {
  tempData: {},
  errors: {},
  isLoading: false,
  isError: false,
};

export const requestResetPass = (state = defaultState, { type, payload }) => {
  switch (type) {
    case requestResetPassNext.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case requestResetPassNext.ERROR:
      if (!payload.details) {
        payload.details = {
          unExpectedError: 'We are sorry, but something went wrong...',
        };
      }
      window.setTimeout(() => errorToast(translate(payload.details?.error?.message || 'Errors.server-error')), 0);
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: {
          ...payload.details,
        },
      };

    case requestResetPassNext.SUCCESS:
      window.setTimeout(() => successToast(translate('Texts.email-sent-success')), 0);
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SAVE_TEMP_DATA:
      return {
        ...state,
        tempData: {
          ...state.tempData,
          email: payload.email,
        },
      };

    default:
      return state;
  }
};
