import Next from '../../../http/Next';

export const deleteAccountNext = new Next('/profile/delete-account', 'DELETE_ACCOUNT', { method: 'post' });
export const archiveAccountInfoNext = new Next(
  '/profile/archive', 'ARCHIVE_ACCOUNT_INFO_NEXT', { method: 'post' },
);
export const checkPWDNext = new Next('/profile/verify-password', 'CHECK_PWD', { method: 'POST' });
export const getTFASeedNext = new Next('/profile/two-factor-seed', 'TFA_SEED');
export const confirmTFANext = new Next('/profile/enable-two-factor', 'CONFIRM_TFA', { method: 'POST' });
export const changePasswordNext = new Next('/profile/change-password', 'CHANGE_PWD', { method: 'POST' });
export const disableTFANext = new Next('/profile/disable-two-factor', 'DISABLE_TFA', { method: 'POST' });
