import Next from '../../http/Next';

export const AUTHENTICATE_TFA_FLOW = new Next('/tfa-flow/authenticate', 'AUTHENTICATE_TFA_FLOW', { method: 'POST' });
export const AUTHENTICATE_TFA_FLOW_PING = new Next('/tfa-flow/authenticate/ping',
  'AUTHENTICATE_TFA_FLOW_PING', { method: 'GET' });
export const REFRESH_TFA_FLOW_SESSION = new Next('/tfa-flow/refresh-session', 'REFRESH_TFA_FLOW_SESSION', { method: 'POST' });
export const UPDATE_USER_INPUTS = 'UPDATE_USER_INPUTS';

export const TFA_FLOW_LIVENESS = new Next('/tfa-flow/liveness', 'TFA_FLOW_LIVENESS', { method: 'GET' });
export const SUBMIT_TFA_VERIFICATION = new Next('/tfa-flow/submit', 'TFA_FLOW_SUBMIT', { method: 'POST' });
