import Cookies from 'js-cookie';

export const setIntent = (type, data) => {
  Cookies.set(
    'investor_intent',
    btoa(
      JSON.stringify(
        {
          type,
          data,
        },
      ),
    ),
    { expires: 6 / 24 },
  );
};

export const hasIntent = () => Boolean(Cookies.get('investor_intent'));

export const getIntent = () => {
  const rawIntent = Cookies.get('investor_intent');
  if (!rawIntent) {
    return null;
  }
  return JSON.parse(atob(rawIntent));
};

export const getRawIntent = () => Cookies.get('investor_intent') || '';

export const setRawIntent = (rawIntent) => Cookies.set('investor_intent', rawIntent, { expires: 6 / 24 });

export const clearIntent = () => {
  Cookies.remove('investor_intent');
};
