import Cookies from 'js-cookie';
import { translate } from '../i18n/Translate';

import { successToast } from '../components/Toast';
import {
  sessionNext,
  loginNext,
  logoutNext,
  resetPassNext,
  UI_LOG_OUT,
  HIDE_LOG_OUT,
  renderInvestorNext,
  renderHiddenInvestorNext,
  getReverseSolicitationNext,
  UPDATE_VERIFICATION_STATUS,
  UPDATE_USER_INFO,
  activateHiddenInvestorNext,
  VERIFY_EMAIL,
  VERIFY_EMAIL_INIT,
  VERIFY_EMAIL_ERROR,
} from './actionTypes';
import { confirmTFANext, disableTFANext, deleteAccountNext } from '../views/Profile/AccountPage/actionTypes';
import { updateInvestorTypeNext } from '../views/Profile/verificationProfile/InvestorType/actionTypes';
import { startVerificationNext } from '../views/Profile/verificationProfile/StatusCheck/actionTypes';
import {
  UPDATE_INVESTOR_SUITABILITY,
} from '../views/Profile/Suitability/actionTypes';
import { createInvestorForUserNext, editBrokerDealerId } from '../views/Profile/actionTypes';
import { setUserInvitePasswordNext } from '../views/SystemPages/AcceptInvitePage/actionTypes';
import { MixPanel } from '../utils/helpers/mixPanel';
import { SUBMIT_ACCREDITATION_NEXT } from '../views/Profile/AccreditationPage/actionTypes';
import { getMeNext } from '../me/actionTypes';
import { getImmediateDataNext } from '../immediate-data/actionTypes';
import { EventsManagerWrapper } from '../events/events-manager-wrapper';
import { queryClient } from '../store';

const authenticate = (token) => {
  if (token) {
    try {
      Cookies.set('auth-token', token, {
        expires: 7,
        path: '/',
      });

      queryClient.resetQueries();
      EventsManagerWrapper.init(token);

      try {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.auth) {
          window.webkit.messageHandlers.auth.postMessage(token);
        } else {
          // console.log('The webkit messageHandlers or auth handler is not available.');
        }
      } catch (error) {
        console.error('Error posting message to webkit:', error);
      }
    } catch (e) {
      console.error(e);
    }
  }
};

const checkLogout = (payload) => {
  if (payload?.statusCode === 401) {
    Cookies.remove('auth-token');
    try {
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.auth) {
        window.webkit.messageHandlers.auth.postMessage('auth-token-removed');
      } else {
        // console.log('The webkit messageHandlers or auth handler is not available.');
      }
    } catch (error) {
      console.error('Error posting message to webkit:', error);
    }
    EventsManagerWrapper.close();
  }
};

const identifyUser = (user) => {
  if (window.zE) {
    try {
      const userName = (user.investorType === 'individual' ? user.fullName : user.entityName) || '';
      if (typeof window.zE.identify === 'function') {
        window.zE.identify({
          name: userName,
          email: user.email,
          organization: (user.investorType === 'individual' ? 'individual' : user.entityName) || 'other',
        });
      } else {
        window.zE('webWidget', 'identify', {
          name: userName,
          email: user.email,
          organization: (user.investorType === 'individual' ? 'individual' : user.entityName) || 'other',
        });
      }
      window.zE('webWidget', 'prefill', {
        name: {
          value: userName,
          readOnly: !!userName, // optional
        },
        email: {
          value: user.email,
          readOnly: !!user.email, // optional
        },
        organization: {
          value: (user.investorType === 'individual' ? 'individual' : user.entityName) || 'other',
          readOnly: true, // optional
        },
      });
    } catch (e) {
      console.error(e);
    }
  }
};

const defaultState = {
  isLoginLoading: false,
  isSessionLoading: true,
  isAuthenticated: false,
  isNewLogin: false,
  isError: false,
  errorType: '',
  token: Cookies.get('auth-token') || null,
  user: null,
  hideLogout: false,
  isUpdating: false,
};
const parseError = (payload) => {
  switch (payload.statusCode) {
    case 401:
      return 'unauthorized';
    case 403:
      return 'forbidden';
    case 400:
      return '';
    case 418:
      return 'reCaptcha';
    case 429:
      return 'tooManyRequests';
    default:
      return 'server-error';
  }
};

export const auth = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_VERIFICATION_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          ...(payload && { verificationStatus: payload }),
        },
      };
    case HIDE_LOG_OUT:
      return {
        ...state,
        hideLogout: true,
      };
    case getReverseSolicitationNext.INIT:
    case loginNext.INIT:
    case resetPassNext.INIT:
      return {
        ...state,
        isLoginLoading: true,
        isError: false,
        errorType: '',
      };
    case loginNext.ERROR:
      checkLogout(payload);
      return {
        ...state,
        isError: true,
        errorType: parseError(payload),
        isLoginLoading: false,
        isAuthenticated: false,
        isNewLogin: false,
        token: null,
        user: null,
      };
    case resetPassNext.ERROR:
      checkLogout(payload);
      return {
        ...state,
        isError: ![403, 409].includes(payload.statusCode),
        errorType: parseError(payload),
        isLoginLoading: false,
        isAuthenticated: false,
        isNewLogin: false,
        token: null,
        user: null,
      };
    case loginNext.SUCCESS:
      authenticate(payload.token);
      if (payload.user?.investorId) {
        identifyUser(payload.user, 'login');
        MixPanel.identify(payload.user);
        MixPanel.set_once(payload.user.investorId);
      }
      MixPanel.track('login');
      return {
        ...state,
        isError: false,
        errorType: '',
        isLoginLoading: false,
        isAuthenticated: true,
        isNewLogin: true,
        token: payload.token,
        user: payload.user,
      };
    case resetPassNext.SUCCESS:
      authenticate(payload.token);
      if (payload.user?.investorId) {
        identifyUser(payload.user);
        MixPanel.identify(payload.user);
      }
      MixPanel.track('reset-password');
      return {
        ...state,
        isError: false,
        errorType: '',
        isLoginLoading: false,
        isAuthenticated: true,
        isNewLogin: true,
        token: payload.token,
        user: payload.user,
      };
    case VERIFY_EMAIL_INIT:
    case logoutNext.INIT:
    case createInvestorForUserNext.INIT:
    case activateHiddenInvestorNext.INIT:
    case renderInvestorNext.INIT:
    case renderHiddenInvestorNext.INIT:
    case sessionNext.INIT:
      return {
        ...state,
        isSessionLoading: true,
      };
    case logoutNext.ERROR:
      if (+payload.status !== 401) {
        return {
          ...state,
          isSessionLoading: false,
        }; // logout can break, we need to keep user knowing that he didnt log out successfully
      }
    // eslint-disable-next-line no-fallthrough
    case logoutNext.SUCCESS:
      window.setTimeout(() => successToast(translate('Texts.logout-success')), 0);
    // eslint-disable-next-line no-fallthrough
    case UI_LOG_OUT: {
      checkLogout(payload);
      // If user is logging out or is logging out UI, we need to close Events Manager
      EventsManagerWrapper.close();
      return {
        ...state,
        isSessionLoading: false,
        isAuthenticated: false,
        isNewLogin: false,
        token: null,
        user: null,
        isError: false,
        errorType: '',
      };
    }
    case getMeNext.ERROR:
    case getImmediateDataNext.ERROR:
    case sessionNext.ERROR:
    case VERIFY_EMAIL_ERROR:
      checkLogout(payload);
      return {
        ...state,
        isSessionLoading: false,
        isAuthenticated: false,
        isNewLogin: false,
        token: null,
        user: null,
      };
    case VERIFY_EMAIL:
      authenticate(payload.token);
      if (payload.user?.investorId) {
        identifyUser(payload.user);
        MixPanel.identify(payload.user);
      }
      MixPanel.track('registration-complete');
      return {
        ...state,
        isSessionLoading: false,
        isAuthenticated: true,
        isNewLogin: true,
        token: payload.token,
        user: payload.user,
      };
    case getImmediateDataNext.SUCCESS:
    case getMeNext.SUCCESS:
      if (state.user?.investorId) {
        identifyUser(state.user);
        MixPanel.identify(state.user);
      }

      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    case activateHiddenInvestorNext.SUCCESS:
      window.setTimeout(() => {
        successToast(translate('Texts.your-individual-account-was-created-successfully'));
      }, 0);
      authenticate(payload.token);
      MixPanel.set_once(payload.user.investorId);
      MixPanel.track('session');
      return {
        ...state,
        isSessionLoading: false,
        isAuthenticated: true,
        isNewLogin: false,
        token: payload.token,
        user: payload.user,
      };
    case createInvestorForUserNext.SUCCESS:
      window.setTimeout(() => {
        successToast(translate(
          'Texts.an-account-for-entity-name-was-created-successfully',
          { entityName: payload.user.entityName },
        ));
      }, 0);
      authenticate(payload.token);
      MixPanel.set_once(payload.user.investorId);
      MixPanel.track('session');
      return {
        ...state,
        isSessionLoading: false,
        isAuthenticated: true,
        isNewLogin: false,
        token: payload.token,
        user: payload.user,
      };
    case sessionNext.SUCCESS:
      authenticate(payload.token);
      MixPanel.set_once(payload.user.investorId);
      MixPanel.track('session');
      return {
        ...state,
        isSessionLoading: false,
        isAuthenticated: true,
        isNewLogin: false,
        token: payload.token,
        user: payload.user,
      };
    case renderInvestorNext.SUCCESS:
      window.setTimeout(() => {
        payload.user?.investorType === 'entity'
          ? successToast(translate('Texts.you-operating-on-behalf-of', {
            entityName: payload?.user?.entityName || payload?.user?.contactEmail,
            entityType: payload?.user?.entityType,
          }))
          : successToast(translate('Texts.you-operating-as', {
            investorName: payload?.user?.fullName || payload?.user?.contactEmail,
          }));
      }, 0);
      authenticate(payload.token);
      if (payload.user?.investorId) {
        identifyUser(payload.user);
        MixPanel.identify(payload.user);
      }
      MixPanel.track('session');
      return {
        ...state,
        isSessionLoading: false,
        isAuthenticated: true,
        isNewLogin: false,
        token: payload.token,
        user: payload.user,
      };
    case renderHiddenInvestorNext.SUCCESS:
      authenticate(payload.token);
      if (payload.user?.investorId) {
        identifyUser(payload.user);
        MixPanel.identify(payload.user);
      }
      MixPanel.track('session');
      return {
        ...state,
        isSessionLoading: false,
        isAuthenticated: true,
        isNewLogin: false,
        token: payload.token,
        user: payload.user,
      };
    case setUserInvitePasswordNext.SUCCESS:
      authenticate(payload.token);
      if (payload.user?.investorId) {
        identifyUser(payload.user);
        MixPanel.identify(payload.user);
      }
      MixPanel.track('set-password');
      return {
        ...state,
        isSessionLoading: false,
        isAuthenticated: true,
        isNewLogin: false,
        token: payload.token,
        user: payload.user,
      };
    case confirmTFANext.SUCCESS:
    case disableTFANext.SUCCESS:
      MixPanel.register(payload);
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    case startVerificationNext.SUCCESS:
    case deleteAccountNext.SUCCESS:
      MixPanel.register(payload);
      return {
        ...state,
        user: {
          ...payload,
        },
      };
    case updateInvestorTypeNext.SUCCESS:
      MixPanel.register({
        ...state.user,
        ...payload,
      });
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    case editBrokerDealerId.INIT:
      return {
        ...state,
        isUpdating: true,
      };

    case editBrokerDealerId.SUCCESS:
      MixPanel.register({
        ...state.user,
        ...payload,
      });
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
        isUpdating: false,
      };

    case editBrokerDealerId.ERROR:
      return {
        ...state,
        isUpdating: false,
        isError: true,
      };

    case SUBMIT_ACCREDITATION_NEXT.SUCCESS:
      MixPanel.register({
        ...state.user,
        accreditationStatus: payload.accreditationStatus,
      });
      return {
        ...state,
        user: {
          ...state.user,
          accreditationStatus: payload.accreditationStatus,
        },
      };
    case UPDATE_INVESTOR_SUITABILITY:
      MixPanel.register({
        ...state.user,
        suitabilityStatus: payload.us.status,
        suitabilityEUStatus: payload.eu.status,
      });
      return {
        ...state,
        user: {
          ...state.user,
          suitabilityStatus: payload.us.status,
          suitabilityEUStatus: payload.eu.status,
        },
      };
    case getReverseSolicitationNext.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          reverseSolicitation: payload.reverseSolicitation,
        },
        isLoginLoading: false,
      };
    case getReverseSolicitationNext.ERROR:
      return {
        ...state,
        user: {
          ...state.user,
          reverseSolicitation: payload.statusCode !== 401,
        },
        isLoginLoading: false,
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    default:
      return state;
  }
};
