import { createHttpAction } from '../http/http';

import {
  sessionNext,
  loginNext,
  logoutNext,
  resetPassNext,
  UI_LOG_OUT,
  HIDE_LOG_OUT,
  renderInvestorNext,
  renderHiddenInvestorNext,
  getReverseSolicitationNext,
  UPDATE_VERIFICATION_STATUS,
  UPDATE_USER_INFO,
  activateHiddenInvestorNext,
  VERIFY_EMAIL,
  VERIFY_EMAIL_INIT,
  VERIFY_EMAIL_ERROR,
} from './actionTypes';

export const getUserSession = (onFinish = () => undefined) => createHttpAction(sessionNext, {}, { onFinish }).action;
export const loginUser = (data, onFinish = () => undefined) => createHttpAction(
  loginNext,
  { body: { ...data } },
  { onFinish },
).action;


export const logoutUser = () => createHttpAction(logoutNext).action;
export const logoutUserUI = () => ({
  type: UI_LOG_OUT,
});
export const setFailedSession = () => ({
  type: sessionNext.ERROR,
});
export const setHideLogOut = () => ({
  type: HIDE_LOG_OUT,
});
export const resetPassAction = (data, options) => createHttpAction(
  resetPassNext,
  { body: { ...data } },
  options,
).action;
export const renderInvestor = (investorId, onFinish = () => undefined) => createHttpAction(
  renderInvestorNext,
  { params: { investorId } },
  { onFinish },
).action;
export const renderHiddenInvestor = (investorId, onFinish = () => undefined) => createHttpAction(
  renderHiddenInvestorNext,
  { params: { investorId } },
  { onFinish },
).action;
export const getReverseSolicitation = () => createHttpAction(getReverseSolicitationNext).action;

export const updateVerificationStatus = (verificationStatus) => (
  { type: UPDATE_VERIFICATION_STATUS, payload: verificationStatus }
);

export const updateUserInfo = ({ user }) => ({ type: UPDATE_USER_INFO, payload: user });

export const activateHiddenInvestor = (onFinish = () => undefined) => createHttpAction(
  activateHiddenInvestorNext,
  {},
  { onFinish },
).action;

// hack for handling verifyEmail state after using react-query hook
export const verifyEmailRedux = ({ data }) => ({ type: VERIFY_EMAIL, payload: data });
export const verifyEmailReduxInit = () => ({ type: VERIFY_EMAIL_INIT });
export const verifyEmailReduxError = ({ statusCode }) => ({ type: VERIFY_EMAIL_ERROR, payload: { statusCode } });
