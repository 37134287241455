import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import './useStatusAlertModal.scss';
import LaddaButton from 'react-ladda';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Translate from '../../../../i18n/Translate';
import folder from './folder.svg';
import phone from './phone.svg';

const useStatusAlertModal = () => {
  const { verificationStatus } = useSelector(({ auth }) => auth.user || {});
  const isBlocked = verificationStatus === 'blocked';
  const isDocumentsExpired = verificationStatus === 'documents-expired';
  const history = useHistory();
  const [isOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const StatusAlertModal = () => (
    <Modal
      size="lg"
      toggle={onClose}
      className="status-alert-modal"
      isOpen={isOpen}
      centered
    >
      <ModalHeader className="border-0">
        <LaddaButton
          size="sm"
          variant="link"
          onClick={onClose}
          className="status-alert-modal__exit-btn text-dark"
        >
          <i className="icon-exit font-20 text-dark" />
        </LaddaButton>
      </ModalHeader>
      <ModalBody className="status-alert-modal__body justify-content-between p-4">
        <div className="mt-3">
          <img src={isBlocked ? phone : folder} alt="" />
          <div className="status-alert-modal__body__title font-18 font-weight-bolder mt-4">
            {isDocumentsExpired && <Translate translationKey="Texts.documents-expired-alert-modal-title" />}
            {isBlocked && <Translate translationKey="Texts.blocked-modal-title" />}
          </div>
          <div className="mt-4">
            {isDocumentsExpired && <Translate translationKey="Texts.documents-expired-alert-modal-description" />}
            {isBlocked && <Translate translationKey="Texts.blocked-modal-description" />}
          </div>
        </div>
        <LaddaButton
          variant="primary"
          size="lg"
          className="m-0"
          onClick={() => {
            if (isBlocked) {
              window.zE.activate();
            } else {
              history.push('/profile/verification/resubmit');
            }
          }}
        >
          {
            isBlocked
              ? <Translate translationKey="Texts.contact-us" />
              : <Translate translationKey="Texts.documents-expired-alert-modal-btn" />
          }
        </LaddaButton>
      </ModalBody>
    </Modal>
  );

  return { StatusAlertModal, openModal };
};

export default useStatusAlertModal;
