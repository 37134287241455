import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { QueryClient } from '@tanstack/react-query';
import rootReducer from './rootReducer';

const composeEnhancers = composeWithDevTools({
  name: 'dashboard',
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer));
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2000 * 60,
    },
  },
});

// export const getStore = () => store;

export default store;
