/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import EnableTwoFAModal from './EnableTwoFAModal';
import SetQRTwoFAModal from './SetQRTwoFAModal';
import NotVerifiedUserModal from './NotVerifiedUserModal';
import { closeModals, showModal } from '../../utils/helpers/actions';
import TFAPasswordModal from './TFAPasswordModal';
import TwoFactorAuthModal from './TwoFactorAuthModal';
import DeleteAccountModal from './DeleteAccountModal';
import RedirectionWarningModal from './RedirectionWarningModal/RedirectionWarningModal';
import MarketReverseSolicitationModal from './MarketReverseSolicitationModal/MarketReverseSolicitationModal';

const ModalWrapper = ({
  modal, closeModals, showModal, options,
}) => {
  const onToggle = (name) => {
    if (modal.name) {
      closeModals();
    } else {
      showModal(name);
    }
  };

  switch (modal.name) {
    case 'EnableTwoFA':
      return (
        <EnableTwoFAModal
          onToggle={() => onToggle('EnableTwoFA')}
          isOpen={modal.name === 'EnableTwoFA'}
        />
      );
    case 'TFAPassword':
      return (
        <TFAPasswordModal
          onToggle={() => onToggle('TFAPassword')}
          isOpen={modal.name === 'TFAPassword'}
        />
      );
    case 'SetQRTwoFA':
      return (
        <SetQRTwoFAModal
          onToggle={() => onToggle('SetQRTwoFA')}
          isOpen={modal.name === 'SetQRTwoFA'}
        />
      );
    case 'NotVerifiedUser':
      return (
        <NotVerifiedUserModal
          onToggle={() => onToggle('NotVerifiedUser')}
          isOpen={modal.name === 'NotVerifiedUser'}
        />
      );
    case 'LoginWithTFA':
      return (
        <TwoFactorAuthModal
          onToggle={() => onToggle('LoginWithTFA')}
          isOpen={modal.name === 'LoginWithTFA'}
        />
      );
    case 'DeleteAccountModal':
      return (
        <DeleteAccountModal
          onToggle={() => onToggle('DeleteAccountModal')}
          isOpen={modal.name === 'DeleteAccountModal'}
        />
      );
    case 'RedirectionWarningModal':
      return (
        <RedirectionWarningModal
          onToggle={() => onToggle('RedirectionWarningModal')}
          isOpen={modal.name === 'RedirectionWarningModal'}
          options={options}
        />
      );
    case 'MarketReverseSolicitationModal':
      return (
        <MarketReverseSolicitationModal
          onToggle={() => onToggle('MarketReverseSolicitationModal')}
          isOpen={modal.name === 'MarketReverseSolicitationModal'}
          options={options}
        />
      );

    default:
      return '';
  }
};

const mapStateToProps = ({ helpers: { modal, options } }) => ({
  modal,
  options,
});

export default connect(mapStateToProps, {
  closeModals,
  showModal,
})(ModalWrapper);
