import React, { useEffect, useState } from 'react';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import { Alert, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import TFADigits from '../TFADigits';
import { getTranslations } from '../../i18n/actions';
import Translate, { translate } from '../../i18n/Translate';
import { disableTFA } from '../../views/Profile/AccountPage/actions';
import ModalComponent from './ModalComponent';
import headerImg from '../../assets/img/other/second_factor_active.svg';
import { loginUser } from '../../auth/actions';
import './TwoFactorModal.scss';
import { authenticateMfaFlow } from '../../views/ResetTFA/actions';
import { doMarketsFlow } from '../../auth/doMarketsFlow';
import { clearIntent, getIntent } from '../../utils/intents';
import { AUTHORIZATION_INTENT, INTENT_FROM_LOGIN } from '../../utils/securitizeIdIntents';
import { getMe } from '../../me/actions';
import './TwoFactorAuthModal.scss';
import { useIsEligibleForTfa } from '../../api/hooks/2fa/useIsEligibleForTfa';

const TwoFactorAuthModal = ({
  onToggle, isOpen, loading, loginUser, isError, errorType, getTranslations, tfaEnabled, disableTFA, options,
}) => {
  const { issuerData } = useSelector(({ issuerAuth }) => issuerAuth);
  const { activeLanguage } = useSelector(({ i18n }) => i18n);
  const [fullCode, setCodeState] = useState('');
  const [reset, setReset] = useState(false);
  const [isTwoFaFlowLoading, setIsTwoFaFlowLoading] = useState(false);
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const intent = getIntent();
  const pendingIntent = intent && [AUTHORIZATION_INTENT, INTENT_FROM_LOGIN].includes(intent.type);
  const isEligibleTFa = useIsEligibleForTfa({ email: options.email, password: options.password });

  const setTwoFACode = (code) => {
    setCodeState(code);
  };

  const handleIntent = (data) => {
    const { brokerDealerId, suitabilityStatus } = data.user;
    const wasRedirect = doMarketsFlow({
      search,
      history,
      brokerDealerId,
      suitabilityStatus,
      issuerData,
    });

    if (!wasRedirect) {
      const { redirectUrl } = intent.data;
      const isUrl = redirectUrl.startsWith('http://') || redirectUrl.startsWith('https://');
      if (isUrl) {
        const isSameDomain = redirectUrl && redirectUrl.startsWith(window.location.origin);
        if (isSameDomain) {
          window.location.href = redirectUrl;
        }
      } else {
        history.push(redirectUrl);
      }
    }
    clearIntent();
  };
  const handleSendCode = () => (
    tfaEnabled
      ? disableTFA({
        password: options.password,
        tfaToken: fullCode,
      }, () => {
        setReset(!reset);
        setCodeState('');
      })
      : loginUser({
        email: (options.email || '').toLowerCase(),
        password: options.password,
        tfaToken: fullCode,
      }, (error, data) => {
        if (!error) {
          if (activeLanguage !== data.user.language) {
            getTranslations(data.user.language);
          }
          dispatch(
            getMe(() => {
              if (pendingIntent) {
                handleIntent(data);
              }
            }),
          );
        }
      })
  );
  const onAuthenticationSuccess = () => {
    setIsTwoFaFlowLoading(false);
    history.push('/reset-tfa');
    onToggle();
  };

  const onAuthenticationError = () => {
    setIsTwoFaFlowLoading(false);
    window.zE.activate();
    onToggle();
  };

  const authenticate = () => authenticateMfaFlow(
    { email: options.email?.toLowerCase(), password: options.password },
    onAuthenticationSuccess,
    onAuthenticationError,
  );

  const onResetButtonClick = async () => {
    setIsTwoFaFlowLoading(true);
    dispatch(authenticate());
  };

  useEffect(() => {
    if (fullCode.length === 6) handleSendCode();
  }, [fullCode]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <ModalComponent
      title="2fa.title"
      isOpen={isOpen}
      onToggle={onToggle}
      name="LoginWithTFA"
      image={headerImg}
      className="tfa-auth-modal"
    >
      <form onSubmit={handleSubmit}>
        <p className="text-center mb-4 m-0 font-16 font-weight-light">
          <Translate translationKey="2fa.enter-code-description" />
        </p>
        {reset
          ? (<span><TFADigits id="twofa-digits" callback={setTwoFACode} /></span>)
          : (<TFADigits id="twofa-digits" callback={setTwoFACode} />)}
        <ModalFooter className="border-0 p-0 flex-column">
          <LaddaButton
            className="btn p-3 mb-3 text-white btn-primary btn-block font-weight-bold"
            loading={loading}
            color="primary"
            data-style={ZOOM_OUT}
            onClick={handleSendCode}
            disabled={fullCode.length < 6}
            type="submit"
          >
            <Translate translationKey="Texts.continue" />
          </LaddaButton>
          {isEligibleTFa.isLoading
            ? null
            : isEligibleTFa.data?.isEligible
              ? (
                <span className="text-center">
                  <Translate translationKey="Texts.2fa.lost-code" />
                  <LaddaButton
                    className="mb-sm-0 mb-2 mt-4 reset-tfa"
                    variant="link"
                    size="lg"
                    loading={isTwoFaFlowLoading}
                    onClick={onResetButtonClick}
                  >
                    <Translate translationKey="Texts.recover-lost-code" />
                  </LaddaButton>
                </span>
              ) : (
                <span className="text-center">
                  <span className="mr-1"><Translate translationKey="Texts.2fa.lost-code" /></span>
                  <span className="mr-1"><Translate translationKey="Texts.contact-us-at" /></span>
                  <span className="mr-1 email">
                    <a href="mailto:investorsupport@securitize.io">investorsupport@securitize.io</a>
                  </span>
                  <Translate translationKey="Texts.for-assistance" />
                </span>
              )}
        </ModalFooter>
        {
          isError && (
            <div className="mt-2 mb-0 form-group">
              <Alert color="danger mb-0 text-center">
                <Translate translationKey={translate(errorType)} />
              </Alert>
            </div>
          )
        }
      </form>
    </ModalComponent>
  );
};

TwoFactorAuthModal.defaultProps = {
  isError: false,
  errorType: '',
};

TwoFactorAuthModal.propTypes = {
  onToggle: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  getTranslations: PropTypes.func.isRequired,
  disableTFA: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  tfaEnabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  isError: PropTypes.bool,
  errorType: PropTypes.string,
};

const mapStateToProps = ({ helpers: { modal, options }, auth: { user } }) => ({
  options,
  isError: modal.isError,
  errorType: modal.errorType,
  loading: modal.loading,
  tfaEnabled: !!(user && user.tfaEnabled),
});

export default connect(
  mapStateToProps,
  {
    loginUser,
    getTranslations,
    disableTFA,
  },
)(TwoFactorAuthModal);
