import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Translate from '../../i18n/Translate';
import './ModalComponent.scss';

const ModalComponent = (props) => {
  const {
    image, className, isOpen, onToggle, name, title, children,
    userStatus, counter, contentClassName,
  } = props;

  const toggle = () => onToggle(name);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={className}
      centered
      contentClassName={contentClassName}
    >
      <ModalHeader
        toggle={toggle}
        tag="div"
        className="w-100 flex-column-reverse align-items-center border-0 p-0"
      >
        {userStatus && (
          <div
            className={classNames(
              'user-data_info__status',
              'font-16',
              userStatus,
            )}
          >
            {userStatus.split('-').join(' ')}
          </div>
        )}
        {counter && (
          <div className="modal-header__counter font-12">
            {counter}
          </div>
        )}
        <div className="d-flex flex-column align-items-center">
          {!!image && <img src={image} className="pt-4 mb-4 pb-2" alt="" />}
          <h4 className="modal-title font-20">
            <Translate translationKey={title} />
          </h4>
        </div>
      </ModalHeader>
      <ModalBody className="p-0 pt-4">
        {children}
      </ModalBody>
    </Modal>
  );
};

ModalComponent.defaultProps = {
  image: '',
  className: '',
  children: null,
  userStatus: '',
  counter: '',
  contentClassName: '',
};

ModalComponent.propTypes = {
  image: PropTypes.string,
  userStatus: PropTypes.string,
  className: PropTypes.string,
  counter: PropTypes.string,
  contentClassName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default ModalComponent;
