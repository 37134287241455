import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { runLastTouchUTMTags } from './lastTouchUTMTags';
import { googleTagManager } from './pixelsScripts';

const GTM_KEY = process.env.REACT_APP_GOOGLE_GTM_KEY;

const GtmScript = ({ gtmKey: gtmKeyProp }) => {
  const gtmKey = gtmKeyProp || GTM_KEY || '';

  useEffect(() => {
    if (gtmKey) {
      googleTagManager(gtmKey);
    }
    runLastTouchUTMTags();
  }, []);

  return (
    <footer>
      <noscript>
        {/* Facebook Pixel Code */}
        <img
          alt=""
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=323512682545462&ev=PageView&noscript=1"
        />
        {/* LinkedIn Pixel */}
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=1104316&fmt=gif"
        />
        {/* Google Tag Manager */}
        {!!gtmKey
          && (
            <iframe
              title="googletagmanager"
              src={`https://www.googletagmanager.com/ns.html?id=${gtmKey}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            />
          )}
      </noscript>
      {/* HubSpot Embed Code */}
      <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/6712825.js" />
    </footer>
  );
};

GtmScript.defaultProps = {
  gtmKey: '',
};

GtmScript.propTypes = {
  gtmKey: PropTypes.string,
};

export default GtmScript;
