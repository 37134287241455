import {
  CLOSE_MODAL_SUCCESS, OPEN_MODAL_SUCCESS, TFA_NOTIFICATION_SUCCESS, REDIRECT_FROM_IE,
  NOT_VERIFY_USER_REMIND, REDIRECT_WARNING_DISABLED, REVERSE_SOLICITATION_MODAL_DISABLED, REDIRECT_URL,
} from './actionTypes';

export const showModal = (name, options) => ({
  type: OPEN_MODAL_SUCCESS,
  payload: {
    name,
    options,
  },
});

export const closeModals = () => ({
  type: CLOSE_MODAL_SUCCESS,
});

export const disableTFANotification = (email) => (
  { type: TFA_NOTIFICATION_SUCCESS, payload: email }
);

export const setRedirectIEFlag = () => (
  { type: REDIRECT_FROM_IE }
);

export const notVerifyUserModalShown = () => (
  { type: NOT_VERIFY_USER_REMIND }
);

export const disableRedirectWarning = (email) => (
  { type: REDIRECT_WARNING_DISABLED, payload: email }
);

export const disableReverseSolicitationModal = (email) => (
  { type: REVERSE_SOLICITATION_MODAL_DISABLED, payload: email }
);

export const updateRedirectUrl = (redirectUrl) => (
  { type: REDIRECT_URL, payload: redirectUrl }
);
