import { OPEN_MODAL_SUCCESS, SAVE_MODAL_OPTIONS } from './actionTypes';
import { confirmTFANext, disableTFANext, getTFASeedNext } from '../../views/Profile/AccountPage/actionTypes';
import { loginNext, VERIFY_EMAIL } from '../../auth/actionTypes';

const defaultState = {};

export const options = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SAVE_MODAL_OPTIONS:
      return {
        ...state,
        ...payload,
      };

    case OPEN_MODAL_SUCCESS:
      return {
        ...state,
        ...payload.options,
      };

    case getTFASeedNext.SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case confirmTFANext.SUCCESS:
    case disableTFANext.SUCCESS:
    case loginNext.SUCCESS:
    case VERIFY_EMAIL:
      return defaultState;

    default: return state;
  }
};
