import { combineReducers } from 'redux';
import { modal } from './modal';
import { tfa } from './tfa';
import { options } from './options';
import { wizardStatus } from './wizardStatus';
import { redirectModal } from './redirectModal';
import { marketReverseSolicitationModal } from './marketReverseSolicitationModal';
import { redirectUrl } from './redirectUrl';

export const helpers = combineReducers({
  modal,
  tfa,
  options,
  wizardStatus,
  redirectModal,
  marketReverseSolicitationModal,
  redirectUrl,
});
