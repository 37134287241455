import { getAddressDocs, updateAddressDocs } from './actionTypes';

const defaultState = {
  isError: false,
  isLoading: false,
  errorType: '',
  uploadedDocs: [],
};

export const addressDocs = (state = defaultState, { type, payload }) => {
  switch (type) {
    case getAddressDocs.INIT:
      return {
        ...state,
        isLoading: true,
      };
    case getAddressDocs.SUCCESS:
    case updateAddressDocs.SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
        uploadedDocs: payload.data,
      };
    case getAddressDocs.ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorType: payload.message,
      };
    case updateAddressDocs.ERROR:
      return {
        ...state,
        errorType: payload.message,
      };
    default:
      return state;
  }
};
