import { errorToast } from '../../../../components/Toast';
import { getIdentityDocs, updateIdentityDocs, UPDATE_INVESTOR_ID_NUMBER } from './actionTypes';

const defaultState = {
  isError: false,
  isLoading: false,
  uploadedDocs: [],
  mainIdentificationNumber: '',
};

export const identityDocuments = (state = defaultState, { type, payload }) => {
  switch (type) {
    case getIdentityDocs.INIT:
      return {
        ...state,
        isLoading: true,
      };
    case getIdentityDocs.SUCCESS:
    case updateIdentityDocs.SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
        uploadedDocs: payload.data,
        mainIdentificationNumber: payload.mainIdentificationNumber || '',
      };
    case getIdentityDocs.ERROR:
    case updateIdentityDocs.ERROR:
      errorToast(payload.message);
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case UPDATE_INVESTOR_ID_NUMBER:
      return {
        ...state,
        mainIdentificationNumber: payload,
      };

    default:
      return state;
  }
};
