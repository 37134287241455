import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert, ModalFooter } from 'reactstrap';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import Translate from '../../i18n/Translate';
import ModalComponent from './ModalComponent';
import { deleteAccount } from '../../views/Profile/AccountPage/actions';
import deleteAccountImg from '../../assets/img/other/delete_img.svg';

const DeleteAccountModal = (props) => {
  const {
    onToggle,
    isOpen,
    deleteAccount,
    isError,
    loading,
    errorType,
  } = props;

  return (
    <ModalComponent
      title="Account.delete"
      image={deleteAccountImg}
      isOpen={isOpen}
      onToggle={onToggle}
      name="DeleteAccountModal"
      contentClassName="delete-account-modal"
    >
      <div className="mt-4 font-16 text-center">
        <Translate translationKey="Texts.delete-account-warning" />
      </div>
      <ModalFooter className="border-0 p-0 flex-column mb-2">
        <LaddaButton
          className="btn m-0 p-3 btn-danger btn-block font-weight-bold text-white font-16"
          loading={loading}
          size="lg"
          data-style={ZOOM_OUT}
          onClick={deleteAccount}
        >
          <Translate translationKey="Texts.delete-account-request" />
        </LaddaButton>
      </ModalFooter>
      {
        isError && (
          <div className="mt-2 mb-0 form-group">
            <Alert color="danger mb-0">
              <Translate translationKey={errorType} />
            </Alert>
          </div>
        )
      }
    </ModalComponent>
  );
};

DeleteAccountModal.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  errorType: PropTypes.string,
};

DeleteAccountModal.defaultProps = {
  errorType: '',
};

const mapStateToProps = ({ helpers: { modal } }) => ({
  isError: modal.isError,
  loading: modal.loading,
  errorType: modal.errorType,
});

export default connect(mapStateToProps, {
  deleteAccount,
})(DeleteAccountModal);
