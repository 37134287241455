import { updateLegalInfoNext, getLegalInfoNext } from './actionTypes';

const defaultState = {
  isInitialized: false,
  isLoading: false,
  isError: false,
  errorType: '',
  data: {},
};

export const legalInfo = (state = defaultState, { type, payload }) => {
  switch (type) {
    case updateLegalInfoNext.INIT:
    case getLegalInfoNext.INIT:
      return {
        ...state,
        isLoading: true,
      };
    case getLegalInfoNext.SUCCESS:
      return {
        ...state,
        data: { ...payload },
        isLoading: false,
        isInitialized: true,
        errorType: '',
        isError: false,
      };
    case getLegalInfoNext.ERROR:
      return {
        ...state,
        isInitialized: false,
        isLoading: false,
        isError: true,
        data: {},
        errorType: payload.message,
      };

    case updateLegalInfoNext.SUCCESS:
      return {
        ...state,
        data: { ...payload },
        isLoading: false,
        errorType: '',
        isError: false,
      };
    case updateLegalInfoNext.ERROR:
      return {
        isLoading: false,
        isError: true,
        errorType: payload.message,
      };
    default:
      return state;
  }
};
