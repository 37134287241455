import { useQuery } from '@tanstack/react-query';
import { getTfaFlowEligibleStatus } from '../../tfa';

const queryKey = 'tfa-flow-eligible';

export const useIsEligibleForTfa = (data, options = {}) => useQuery(
  [queryKey, data],
  () => getTfaFlowEligibleStatus(data),
  options,
);
