import {
  addEntityInvestorSignerAction,
  addIndividualInvestorSignerAction,
  addIndividualInvestorSignerAddressAction, addInvestorSignerDocumentsAction,
  resetInvestorSignerAction,
} from './actionTypes';

const initialState = {
  documents: [],
};
export const currentSigner = (state = initialState, { type, payload }) => {
  switch (type) {
    case addIndividualInvestorSignerAction:
      return {
        ...state,
        isBeneficialOwner: payload.isBeneficialOwner,
        uboOwning: payload.uboOwning,
        isLegalSigner: payload.isLegalSigner,
        signerType: payload.signerType,
        birthDate: payload.birthDate,
        documents: payload.documents,
        email: payload.email,
        taxCountryCode: payload.taxCountryCode,
        taxId: payload.taxId,
        individualName: {
          firstName: payload.firstName,
          middleName: payload.middleName,
          lastName: payload.lastName,
        },
      };
    case resetInvestorSignerAction:
      return {
        ...initialState,
      };
    case addIndividualInvestorSignerAddressAction:
      return {
        ...state,
        address: payload.address,
      };
    case addInvestorSignerDocumentsAction:
      return {
        ...state,
        documents: payload.documents,
      };

    case addEntityInvestorSignerAction:
      return {
        signerType: payload.signerType,
        birthDate: payload.birthDate,
        legalName: payload.legalName,
        businessName: payload.business,
        entityType: payload.entityType,
        email: payload.email,
        taxId: payload.entityId,
        taxCountryCode: payload.taxCountryCode,
        documents: payload.documents,
      };
    default:
      return state;
  }
};
