import {
  AUTHENTICATE_TFA_FLOW,
  REFRESH_TFA_FLOW_SESSION,
  SUBMIT_TFA_VERIFICATION,
  UPDATE_USER_INPUTS,
} from './actionTypes';

const defaultState = {
  isLoading: false,
  isError: false,
  data: {},
  userInputs: {},
};

export const resetTFA = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_USER_INPUTS:
      return {
        ...state,
        userInputs: payload,
      };
    case REFRESH_TFA_FLOW_SESSION.INIT:
    case AUTHENTICATE_TFA_FLOW.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: {},
      };
    case REFRESH_TFA_FLOW_SESSION.ERROR:
    case AUTHENTICATE_TFA_FLOW.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: {},
      };
    case SUBMIT_TFA_VERIFICATION.SUCCESS:
    case REFRESH_TFA_FLOW_SESSION.SUCCESS:
    case AUTHENTICATE_TFA_FLOW.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: {
          ...state.data,
          ...payload,
        },
      };

    default:
      return state;
  }
};
