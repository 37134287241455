import { getSignersDocumentsNext, startVerificationNext, START_REDIRECT } from './actionTypes';

const defaultState = {
  isError: false,
  errorType: '',
  loading: false,
  isStartingVerification: false,
  data: [],
  signers: [],
  redirectUrl: '',
};

export const investorDocs = (state = defaultState, { type, payload }) => {
  switch (type) {
    case START_REDIRECT:
      return {
        ...state,
        redirectUrl: payload,
      };
    case startVerificationNext.INIT:
      return {
        ...state,
        isStartingVerification: true,
      };
    case startVerificationNext.ERROR:
    case startVerificationNext.SUCCESS:
      return {
        ...state,
        isStartingVerification: false,
      };
    case getSignersDocumentsNext.INIT:
      return {
        ...state,
        loading: true,
      };
    case getSignersDocumentsNext.SUCCESS:
      return {
        ...state,
        isError: false,
        errorType: '',
        loading: false,
        signers: payload || [],
      };
    case getSignersDocumentsNext.ERROR:
      return {
        ...state,
        isError: true,
        errorType: payload.message,
        loading: false,
      };
    default:
      return state;
  }
};
