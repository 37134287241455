import { getAdditionalInfoNext, updateAdditionalInfoNext } from './actionTypes';
import { updateLegalInfoNext } from '../../verificationProfile/LegalInformation/actionTypes';

const defaultState = {
  isInitialized: false,
  isLoading: false,
  isError: false,
  data: [],
};

export const additionalInfo = (state = defaultState, { type, payload }) => {
  switch (type) {
    case getAdditionalInfoNext.INIT:
      return {
        ...state,
        isLoading: true,
      };

    case getAdditionalInfoNext.SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        isInitialized: true,
      };

    case updateLegalInfoNext.SUCCESS:
      return {
        ...state,
        data: payload.tax,
        isLoading: false,
        isInitialized: true,
      };

    case getAdditionalInfoNext.ERROR:
      return {
        ...state,
        isInitialized: false,
        isLoading: false,
        isError: true,
      };

    case updateAdditionalInfoNext.SUCCESS:
      return {
        ...state,
        data: payload,
        isInitialized: true,
      };

    case updateAdditionalInfoNext.ERROR:
      return {
        ...state,
        isInitialized: false,
      };

    default:
      return state;
  }
};
