import {
  approveAuthNext, authRequestStatusNext, getIssuerInfoNext, setGetIssuerInfoLoading, getPublicIssuerInfoNext,
} from './actionTypes';

const defaultState = {
  getIssuerInfoLoading: false,
  isAuthorized: false,
  isAuthLoading: true,
  isLoading: true,
  isError: false,
  errorType: '',
  issuerData: {},
};

export const issuerAuth = (state = defaultState, { type, payload }) => {
  switch (type) {
    case setGetIssuerInfoLoading:
      return {
        ...state,
        getIssuerInfoLoading: payload.isLoading,
      };
    case getIssuerInfoNext.INIT:
      return {
        ...state,
        getIssuerInfoLoading: true,
        isError: false,
        isLoading: true,
        errorType: '',
      };
    case approveAuthNext.INIT:
      return {
        ...state,
        isError: false,
        isLoading: true,
        errorType: '',
      };
    case getIssuerInfoNext.ERROR:
      return {
        ...state,
        getIssuerInfoLoading: false,
        isLoading: false,
        errorType: payload.message || 'server error',
        isError: true,
      };
    case approveAuthNext.ERROR:
      return {
        ...state,
        isLoading: false,
        errorType: payload.message || 'server error',
        isError: true,
      };
    case getPublicIssuerInfoNext.SUCCESS:
      return {
        ...state,
        issuerData: {
          ...(state.issuerData || {}),
          ...(payload || {}),
        },
      };
    case getIssuerInfoNext.SUCCESS:
      return {
        ...state,
        isError: false,
        getIssuerInfoLoading: false,
        isLoading: false,
        errorType: '',
        issuerData: payload || {},
      };

    case authRequestStatusNext.INIT:
      return {
        ...state,
        isAuthLoading: true,
      };
    case authRequestStatusNext.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthorized: true,
        isAuthLoading: false,
      };
    case authRequestStatusNext.ERROR: // on auth exists error we need to show the authorization screen, otherwise to redirect.
      if (payload.statusCode === 401) {
        return {
          ...state,
          isLoading: false,
        };
      }
      return {
        ...state,
        isLoading: false,
        isAuthorized: false,
        isAuthLoading: false,
        errorType: payload.message || 'server error',
        isError: true,
      };
    // case approveAuthNext.SUCCESS:
    default:
      return state;
  }
};
