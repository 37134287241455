import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Card } from 'reactstrap';
import './SettingsCard.scss';
import DefaultContent from './DefaultContent';
import { ReactComponent as IconAccountTaxCenter } from '../../../../assets/img/other/account-tax-center.svg';
import {
  ReactComponent as IconAccountAdditionalInformation,
} from '../../../../assets/img/other/account-additional-information.svg';
import {
  ReactComponent as IconAccountPersonalInformation,
} from '../../../../assets/img/other/account-personal-information.svg';
import {
  ReactComponent as IconAccountAccreditationQualification,
} from '../../../../assets/img/other/account-accreditation-qualification.svg';
import { ReactComponent as IconAccountDocuments } from '../../../../assets/img/other/account-documents.svg';
import { ReactComponent as IconEntityAccount } from '../../../../assets/img/other/account-entity.svg';
import { ReactComponent as IconAccountIndividual } from '../../../../assets/img/other/account-individual.svg';
import { ReactComponent as IconAccountLegalSigners } from '../../../../assets/img/other/account-legal-signers.svg';
import { ReactComponent as IconAccountIdentityDocuments } from '../../../../assets/img/other/account-identity-documents.svg';
import { ReactComponent as IconAccountInformation } from '../../../../assets/img/other/account-information.svg';
import { ReactComponent as IconSettingsLoginInformation } from '../../../../assets/img/other/settings-login-information.svg';
import { ReactComponent as IconSettingsLanguage } from '../../../../assets/img/other/settings-language.svg';
import { ReactComponent as IconAccountDocumentsAndStatements } from '../../../../assets/img/other/statements.svg';

const getTag = (type, props, children) => {
  switch (type) {
    case 'Link':
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Link {...props}>{children}</Link>
      );
    case 'a':
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <a {...props}>{children}</a>
      );
    default:
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div {...props}>{children}</div>
      );
  }
};

const usedIconsList = [
  'icon-account',
  'icon-articles',
  'icon-tax-center',
  'icon-certificate',
  'icon-folder',
  'icon-documents-statements',
  'icon-profile',
  'icon-business',
  'icon-notes',
  'icon-password',
  'icon-legal-signers',
  'icon-passport',
  'icon-account-entity',
];

const SettingsCard = ({
  iconClassName, title, paragraph, status, done, size, className, href, absHref, children, id, disableContentPaddingTop, disabled,
}) => {
  const tagName = (href || absHref) && !done ? absHref ? 'a' : 'Link' : 'div';
  const iconSize = {
    'font-24': size === 'small',
    'font-48': size === 'big',
  };

  const getParagraph = () => {
    switch (status) {
      case 'pending':
        return 'Account.investor-details-check';

      case 'service-denied':
        return 'Service denied';

      default: return '';
    }
  };

  return (
    <Card className={classNames('set-card', 'mb-0', `card-${size}`, className, {
      'border-0': ['accreditation-card', 'suitability-card', 'details-card', 'complete-details-card'].includes(id),
      'pl-3': !iconClassName,
      'set-card--denied': ['service-denied', 'rejected', 'expired'].includes(status),
      // eslint-disable-next-line max-len
      'set-card--pending': ['pending', 'not-qualified', 'updates-required', 'documents-expired', 'update-documents'].includes(status),
      'set-card--done': done,
      'set-card--disabled': disabled,
    })}
    >
      {
        getTag(
          tagName,
          {
            id,
            className: 'd-flex p-0 align-items-center card-body',
            to: href && !done ? href : undefined,
            href: absHref && !done ? absHref : undefined,
          },
          (
            <>
              {iconClassName && (
                <div
                  className={classNames('set-card_icon d-flex align-items-center justify-content-center', {
                    'font-24': size === 'small',
                    'font-32': size === 'big',
                  })}
                >
                  {iconClassName === 'icon-account' && (
                    <i className="icon-wrapper"><IconAccountPersonalInformation className="icon-text-inherit" /></i>
                  )}
                  {iconClassName === 'icon-account-entity' && (
                    <i className="icon-wrapper"><IconAccountInformation className="icon-text-inherit" /></i>
                  )}
                  {iconClassName === 'icon-articles' && (
                    <i className="icon-wrapper"><IconAccountAdditionalInformation className="icon-text-inherit" /></i>
                  )}
                  {iconClassName === 'icon-tax-center' && (
                    <i className="icon-wrapper"><IconAccountTaxCenter className="icon-wrapper icon-text-inherit" /></i>
                  )}
                  {iconClassName === 'icon-certificate' && (
                    <i className="icon-wrapper">
                      <IconAccountAccreditationQualification className="icon-wrapper icon-text-inherit" />
                    </i>
                  )}
                  {iconClassName === 'icon-folder' && (
                    <i className="icon-wrapper"><IconAccountDocuments className="icon-wrapper icon-text-inherit" /></i>
                  )}
                  {iconClassName === 'icon-documents-statements' && (
                    <i className="icon-wrapper">
                      <IconAccountDocumentsAndStatements className="icon-wrapper icon-text-inherit" />
                    </i>
                  )}
                  {iconClassName === 'icon-profile' && (
                    <i className="icon-wrapper"><IconAccountIndividual className="icon-wrapper icon-text-inherit" /></i>
                  )}
                  {iconClassName === 'icon-business' && (
                    <i className="icon-wrapper"><IconEntityAccount className="icon-wrapper icon-text-inherit" /></i>
                  )}
                  {iconClassName === 'icon-notes' && (
                    <i className="icon-wrapper"><IconSettingsLoginInformation className="icon-wrapper icon-text-inherit" /></i>
                  )}
                  {iconClassName === 'icon-password' && (
                    <i className="icon-wrapper"><IconSettingsLanguage className="icon-wrapper icon-text-inherit" /></i>
                  )}
                  {iconClassName === 'icon-legal-signers' && (
                    <i className="icon-wrapper"><IconAccountLegalSigners className="icon-wrapper icon-text-inherit" /></i>
                  )}
                  {iconClassName === 'icon-passport' && (
                    <i className="icon-wrapper"><IconAccountIdentityDocuments className="icon-text-inherit" /></i>
                  )}
                  {!usedIconsList.includes(iconClassName) && (
                    <i className={classNames(iconClassName, 'icon-text-inherit')} />
                  )}
                </div>
              )}
              <div className={classNames('set-card_data d-flex flex-column font-24', {
                'set-card_data--disable-padding-top': disableContentPaddingTop,
              })}
              >
                {children || (
                  <DefaultContent
                    paragraph={!status ? paragraph : getParagraph()}
                    status={status}
                    size={size}
                    title={title}
                  />
                )}
              </div>
              <div
                className={classNames('set-card_status d-flex align-items-center justify-content-center', iconSize)}
              >
                {
                  done
                    ? <i className="icon-check icon-text-inherit" />
                    : (['pending', 'service-denied'].includes(status))
                      ? (
                        <i className={classNames('icon-faq', {
                          'set-card--denied__status': status === 'service-denied',
                          'set-card--pending__status': ['pending'].includes(status),
                        })}
                        />
                      )
                      : <i className="icon-arrow_right" />
                }
              </div>
            </>
          ),
        )
      }
    </Card>
  );
};

SettingsCard.defaultProps = {
  disabled: false,
  done: false,
  status: '',
  className: '',
  paragraph: '',
  title: '',
  href: '',
  absHref: '',
  iconClassName: '',
  size: 'small',
  children: null,
  disableContentPaddingTop: false,
};

SettingsCard.propTypes = {
  disabled: PropTypes.bool,
  done: PropTypes.bool,
  iconClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  paragraph: PropTypes.string,
  status: PropTypes.string,
  disableContentPaddingTop: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'big']),
  className: PropTypes.string,
  href: PropTypes.string,
  absHref: PropTypes.string,
  children: PropTypes.element,
};

export default SettingsCard;
