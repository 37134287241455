import { combineReducers } from 'redux';
import { i18n } from './i18n/reducer';
import { auth } from './auth/reducer';
import { register } from './views/SystemPages/Registration/CreateAccount/reducer';
import { helpers } from './utils/helpers/reducer';
import { profile } from './views/Profile/reducer';
import { issuerAuth } from './views/Authorize/reducer';
import { identityDocuments } from './views/Profile/verificationProfile/IdentityVerification/reducer';
import { sessions } from './views/Profile/AccountPage/components/SignedInDevices/reducer';
import { requestResetPass } from './views/SystemPages/RequestResetPass/reducer';
import { verifyResetPassCode } from './views/SystemPages/VerifyResetPassCode/reducer';
import { market } from './views/Market/reducer';
import { featureFlags } from './utils/featureFlags/reducer';
import { importInformation } from './views/SystemPages/ImportInformation/reducer';
import { resetTFA } from './views/ResetTFA/reducer';
import { me } from './me/reducer';
import { investments } from './views/Investments/reducer';

export default combineReducers({
  me,
  auth,
  i18n,
  register,
  profile,
  helpers,
  sessions,
  issuerAuth,
  identityDocuments,
  requestResetPass,
  verifyResetPassCode,
  investments,
  market,
  featureFlags,
  importInformation,
  resetTFA,
});
