import { createHttpAction } from '../../../http/http';
import {
  deleteAccountNext, archiveAccountInfoNext, changePasswordNext, checkPWDNext, confirmTFANext, disableTFANext, getTFASeedNext,
} from './actionTypes';

export const deleteAccount = (options) => (
  createHttpAction(deleteAccountNext, { body: {} }, options).action
);
export const archiveAccountInfo = (options) => (
  createHttpAction(archiveAccountInfoNext, { body: {} }, options).action
);

export const checkPWD = (password, onSuccess = () => null) => (dispatch) => {
  dispatch({ type: 'SAVE_MODAL_OPTIONS', payload: { password } });
  dispatch(createHttpAction(
    checkPWDNext,
    { body: { password } },
    { onSuccess },
  ).action);
};

export const getTFASeed = () => createHttpAction(getTFASeedNext).action;

export const confirmTFACode = (tfaToken, password, onError = undefined) => createHttpAction(
  confirmTFANext,
  { body: { tfaToken, password } }, { onError },
).action;

export const changePassword = (currentPassword, newPassword, onSuccess = () => null, onError = () => null) => createHttpAction(
  changePasswordNext,
  { body: { currentPassword, newPassword } },
  { onSuccess, onError },
).action;

export const disableTFA = ({ tfaToken, password }, onError = () => null) => createHttpAction(
  disableTFANext,
  { body: { tfaToken, password } }, { onError },
).action;
