import urljoin from 'url-join';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

if (!BASE_URL) {
  throw new Error('REACT_APP_API_BASE_URL is undefined and is required for redux http requests!');
}

export default class Next {
  constructor(url, seed, options = {}) {
    if (!seed || !url) {
      throw new Error('Seed and URL are required');
    }
    this.seed = `${seed}`;
    this.options = {
      uri: (
        url.startsWith('http://')
        || url.startsWith('https://')
        || url.startsWith('//')
      ) ? url : urljoin(BASE_URL, url),
      json: true,
      ...options,
    };
  }

  get INIT() {
    return `${this.seed}_INIT`;
  }

  get SUCCESS() {
    return `${this.seed}_SUCCESS`;
  }

  get ERROR() {
    return `${this.seed}_ERROR`;
  }


  get PROGRESS() {
    return `${this.seed}_PROGRESS`;
  }

  get ABORT() {
    return `${this.seed}_ABORT`;
  }
}
