import React from 'react';
import './InvestorStatusAlert.scss';
import LaddaButton from 'react-ladda';

import classnames from 'classnames';

import { useSelector } from 'react-redux';
import Translate from '../../i18n/Translate';
import alert from './alert.svg';
import error from './error.svg';
import useStatusAlertModal from './components/useStatusAlertModal';

const InvestorStatusAlert = () => {
  const { verificationStatus } = useSelector(({ auth }) => auth.user || {});
  const isBlocked = verificationStatus === 'blocked';
  const isDocumentsExpired = verificationStatus === 'documents-expired';
  const isExpired = verificationStatus === 'expired';

  const { StatusAlertModal, openModal } = useStatusAlertModal();
  return null;
  // eslint-disable-next-line no-unreachable
  return (isBlocked || isDocumentsExpired || isExpired) && (
    <div className={classnames('investor-status-alert p-3 mb-3', {
      'investor-status-alert--warning': isDocumentsExpired || isExpired,
      'investor-status-alert--error': isBlocked,
    })}
    >
    <StatusAlertModal />
      <div className="investor-status-alert__content">
        <img className="align-self-start" src={(isDocumentsExpired || isExpired) ? alert : error} alt="" />
        <div>
          {!isBlocked && (
            <div className="font-16 font-weight-bolder"><Translate translationKey="Texts.documents-expired-alert-title" /></div>
          )}
          <div className="font-16">
            {isExpired && <Translate translationKey="Texts.documents-expired-30-days-ago-alert-description" />}
            {isDocumentsExpired && <Translate translationKey="Texts.documents-expired-alert-description" />}
            {isBlocked && <Translate translationKey="Texts.blocked-alert-title-description" />}
          </div>
        </div>
      </div>
      <div className="investor-status-alert__button d-flex justify-content-center justify-content-md-end">
        <LaddaButton
          variant="secondary"
          size="lg"
          className="m-0"
          onClick={openModal}
        >
          {
            isBlocked
              ? <Translate translationKey="Texts.contact-us" />
              : <Translate translationKey="Texts.documents-expired-alert-btn" />
          }
        </LaddaButton>
      </div>

    </div>
  );
};

export default InvestorStatusAlert;
