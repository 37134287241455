import React, {
  Component, Suspense, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Redirect, Route, Switch, withRouter, useLocation, useHistory,
} from 'react-router-dom';
import classnames from 'classnames';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Container } from 'reactstrap';
import { Flip, ToastContainer } from 'react-toastify';
import queryString from 'query-string';
import LanguageSelect from '../../components/LanguageSelect/LanguageSelect';
import { onErrorMiddleware } from '../../http/http';
import SecuritizePage from '../../components/SecuritizePage/SecuritizePage';
import { getTranslations } from '../../i18n/actions';
import { translate } from '../../i18n/Translate';
// eslint-disable-next-line import/no-unresolved
import GtmScript from '../../components/GtmScripts/GtmScript';
import { infoToast, errorToast } from '../../components/Toast';

import MainMenu from './components/MainMenu';
import SecureRoute from '../SecureRoute/SecureRoute';
import {
  getUserSession, setFailedSession, updateVerificationStatus, renderInvestor, renderHiddenInvestor,
} from '../../auth/actions';
// routes config
import routes from '../../routes';
import ModalWrapper from '../../components/ModalComponent/ModalWrapper';
import RouterFollow from '../../utils/RouterFollow';
import ZenDeskWebWidget from './components/ZenDeskWebWidget';
import { getFeatureFlags } from '../../utils/featureFlags/actions';
import { useStatusAlertModal } from '../../components/InvestorStatusAlert';
import { getMe } from '../../me/actions';
import { useGetImmediateData } from '../../api/hooks/immediate-data/useGetImmediateData';

const containerStyle = { zIndex: 1999 };

const StatusAlertModalWrapper = () => {
  const { StatusAlertModal, openModal } = useStatusAlertModal();
  useEffect(() => {
    onErrorMiddleware((next, dispatchActions, data, error) => {
      const { status } = error;
      const isBlocked = data.details && data.details.got && data.details.got.includes('blocked');
      if (status === 403 && isBlocked) {
        openModal();
      }
    });
  }, []);
  return <StatusAlertModal />;
};

const ImmediateData = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isShowRefreshBtn = ['account', 'primary-market', 'secondary-market'].includes(pathname.replace('/', ''));
  const isShowInfoToast = !['profile/accreditation', 'profile/suitability'].includes(pathname.replace('/', ''));
  const user = useSelector(({ auth }) => auth.user);
  const [shouldFetch, setShouldFetch] = useState(user?.verificationStatus === 'pending');
  const immediateData = useGetImmediateData({ enabled: shouldFetch, refetchInterval: 30000 });

  // eslint-disable-next-line max-len
  const verificationStatusCapitalised = immediateData.data?.verificationStatus.replace(/^./, immediateData.data?.verificationStatus[0].toUpperCase());
  const verificationStatusTextVerified = translate('Texts.toast-info-verification-status-changed-to-verified',
    { verificationStatus: verificationStatusCapitalised });

  useEffect(() => {
    setShouldFetch(user?.verificationStatus === 'pending');
  }, [user?.verificationStatus]);

  useEffect(() => {
    setShouldFetch(immediateData.data?.verificationStatus === 'pending');
    if (immediateData.data?.verificationStatus === 'verified') {
      dispatch(updateVerificationStatus(immediateData.data?.verificationStatus));
      if (isShowInfoToast) {
        infoToast(verificationStatusTextVerified, isShowRefreshBtn);
      }
    }
  }, [immediateData.data?.verificationStatus]);


  return null;
};

const LoginIndividualInvestorForKYC = () => {
  const user = useSelector(({ auth }) => auth.user);
  const { pathname, search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    // It's a hack, pathname dose not render fast enough.
    const isAccessingVerification = window?.location?.pathname?.includes('verification');
    // Same hack as with isAccessingVerification above.
    const isVerificationForIndividual = window?.location?.search?.includes('forIndividual');
    const isLoggedInAsEntity = user?.investorType === 'entity';

    if (isAccessingVerification && isVerificationForIndividual && isLoggedInAsEntity) {
      const queryParamsWithEntityInvestorIdForReloginOnVerificationProcessEnd = `${search}&investorId=${user.investorId}`;
      const userIndividualInvestorId = user?.userInvestors
        ?.find((investor) => investor?.details?.investorType === 'individual')?.investorId;

      dispatch(
        renderHiddenInvestor(
          userIndividualInvestorId,
          () => history.push(queryParamsWithEntityInvestorIdForReloginOnVerificationProcessEnd),
        ),
      );
    }
  }, [pathname, user, search]);

  return null;
};

const RestrictAccessForHiddenInvestor = () => {
  const history = useHistory();
  const user = useSelector(({ auth }) => auth.user);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // It's a hack, pathname dose not render fast enough.
    const isAccessingVerification = window?.location?.pathname?.includes('verification');
    if (!isAccessingVerification && user?.isHidden) {
      const lastLoggedInNonHiddenInvestorId = user?.userInvestors
        ?.filter(({ isHidden }) => !isHidden)
        ?.sort((a, b) => b.lastLoggedIn - a.lastLoggedIn)
        ?.[0]?.investorId;

      // eslint-disable-next-line no-console
      console.log(`DefaultLayout isHidden: ${user?.isHidden}`);
      dispatch(
        renderInvestor(lastLoggedInNonHiddenInvestorId, () => {
          if (window?.location?.pathname?.includes('authorize')) return;
          history.push('/portfolio');
        }),
      );
    }
  }, [pathname, user]);

  return null;
};

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInternalServerError: false,
      hasCheckedReLogin: false,
    };
  }

  componentDidMount() {
    const { getTranslations, getFeatureFlags, activeLanguage } = this.props;
    getFeatureFlags();
    getTranslations(activeLanguage);
    this.handleSession();
    onErrorMiddleware((next, dispatchActions, data, error) => {
      const { status, response } = error;
      if (status === 500) {
        if (response?.req?.url?.endsWith('/issued') || response?.req?.url?.endsWith('/investments/cash-account')) {
          return;
        }
        if (response?.req?.url?.includes('/wallets') && response?.req?.method === 'POST') {
          errorToast('Texts.failure-add-wallet');
          return;
        }
        this.setState({ isInternalServerError: true });
      }
    });
  }

  componentDidUpdate() {
    const { isMeLoading, isAuthenticated, isLoading } = this.props;
    const { hasCheckedReLogin } = this.state;

    if (isLoading || !isAuthenticated || isMeLoading || hasCheckedReLogin) {
      return;
    }

    this.reLoginAsIntendedInvestor();
  }

  reLoginAsIntendedInvestor = () => {
    const {
      user, location, history, renderInvestor,
    } = this.props;
    const { pathname, search, hash } = location;

    this.setState({ hasCheckedReLogin: true });
    const { forInvestor: intendedInvestorId } = queryString.parse(search) || {};

    if (!intendedInvestorId) return;

    const queryParamsExcludingIntendedInvestorId = new URLSearchParams(search);
    queryParamsExcludingIntendedInvestorId.delete('forInvestor');

    const fullPathName = `${pathname}${hash}`;

    let redirectUrl = fullPathName === '/' ? '/portfolio' : fullPathName;
    redirectUrl += queryParamsExcludingIntendedInvestorId.size > 0 ? `?${queryParamsExcludingIntendedInvestorId.toString()}` : '';

    if (intendedInvestorId === user.investorId) {
      return history.push(redirectUrl);
    }

    const canUserAccessIntendedInvestor = !!user?.userInvestors?.find(({ investorId }) => investorId === intendedInvestorId);

    if (!canUserAccessIntendedInvestor) {
      const name = user?.entityName || user?.fullName || user?.email || '';
      history.push('portfolio');
      errorToast(translate('Errors.not-allowed-to-enter', { name }));
      return;
    }

    renderInvestor(intendedInvestorId, () => history.push(redirectUrl));
  };

  handleSession = () => {
    const {
      token, getUserSession, setFailedSession, getMe,
    } = this.props;
    if (token) {
      getUserSession((error, data) => {
        const {
          getTranslations, activeLanguage, translations,
        } = this.props;
        const hasTranslations = Object.keys(translations).length;
        if (error) {
          if (!hasTranslations) {
            getTranslations(activeLanguage);
          }
        } else {
          getMe();
          if (activeLanguage !== data.user.language) {
            getTranslations(data.user.language);
          }
        }
      });
    } else {
      setFailedSession();
    }
  }

  loading = () => (
    <div className="loader animated fadeIn pt-1 text-center">
      <div className="sk-spinner sk-spinner-pulse" />
    </div>
  );

  redirectAuthenticatedUser = () => {
    const {
      user,
      isAuthenticated,
      location,
    } = this.props;
    const {
      search,
    } = location;

    // eslint-disable-next-line no-console
    console.log('redirectAuthenticatedUser, start');

    if (window.location.hash && window.location.hash.startsWith('#/')) {
      const redirectTo = window.location.hash.replace('#', '');
      return redirectTo;
    }

    const { code: authorizedAccountCode, email = '' } = queryString.parse(search) || {};
    const redirectToRegistrationOrLogin = window.localStorage.getItem('email') ? 'login' : '/registration';
    let redirectTo = isAuthenticated ? '/portfolio' : redirectToRegistrationOrLogin;

    if (isAuthenticated && authorizedAccountCode) {
      redirectTo = `/profile/authorized-accounts?email=${encodeURIComponent(email)}&code=${authorizedAccountCode}`;
    }

    const isAccessingVerification = window.location.pathname.includes('verification');
    if (isAccessingVerification && user?.isHidden) {
      // eslint-disable-next-line no-console
      console.log('redirectAuthenticatedUser, verification for hidden investor');
      return;
    }

    // eslint-disable-next-line no-console
    console.log(`redirectAuthenticatedUser, redirectTo: ${redirectTo}`);
    return redirectTo;
  }

  render() {
    const {
      isMeLoading,
      isLoading,
      isAuthenticated,
      isTranslationError,
      location: { pathname },
    } = this.props;
    const { isInternalServerError } = this.state;

    const isAuthorize = pathname === '/authorize';
    const isMarketDisclaimer = pathname === '/market/share-info';
    const is404Page = pathname === '/404';
    const isImportPage = pathname === '/import';

    const showMenu = !isAuthorize && !isMarketDisclaimer && !is404Page && !isImportPage;
    const canSkipMeData = !!['/wallets', '/portfolio', '/profile', '/settings']
      .find((path) => pathname.startsWith(path));

    return (
      <SecuritizePage
        isLoading={isMeLoading ? !canSkipMeData : isLoading}
        isTranslationError={isTranslationError || isInternalServerError}
      >
        <RouterFollow />
        <ZenDeskWebWidget />
        <div className="app securitize-app position-relative">
          <ToastContainer
            transition={Flip}
            position="bottom-right"
            autoClose={5000}
            style={containerStyle}
            closeButton={false}
          />
          <ModalWrapper />
          <StatusAlertModalWrapper />
          {/* TODO: handle this conditions in componentDidUpdate instead of creating a component for each one */}
          {
            isAuthenticated && <RestrictAccessForHiddenInvestor />
          }
          {
            isAuthenticated && <LoginIndividualInvestorForKYC />
          }
          {
            isAuthenticated && <ImmediateData />
          }
          {
            showMenu && (
              <MainMenu pathname={pathname} authenticated={isAuthenticated && !isAuthorize} />
            )
          }
          {
            (!isAuthenticated || isAuthorize) && !is404Page && <LanguageSelect />
          }
          <div className={classnames('app-body securitize-app__body', {
            'securitize-app__body--no-menu': !isAuthenticated || !showMenu,
            'securitize-app__body--registration': !isAuthenticated,
          })}
          >
            <main className="main">
              <Container fluid>
                <Suspense fallback={this.loading()}>
                  <Switch>
                    {routes.map((route) => (route.component ? (
                      route.secure === false ? (
                        <Route
                          key={route.path}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          component={route.component}
                        />
                      ) : (
                        <SecureRoute
                          key={route.path}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          component={route.component}
                        />
                      )
                    ) : (null)))}
                    <Redirect
                      from="/"
                      to={this.redirectAuthenticatedUser()}
                    />
                  </Switch>
                </Suspense>
              </Container>
            </main>
          </div>
        </div>
        <GtmScript gtmKey={process.env.REACT_APP_GOOGLE_GTM_KEY} />
      </SecuritizePage>
    );
  }
}


DefaultLayout.propTypes = {
  setFailedSession: PropTypes.func.isRequired,
  getUserSession: PropTypes.func.isRequired,
  getFeatureFlags: PropTypes.func.isRequired,
  getTranslations: PropTypes.func.isRequired,
  getMe: PropTypes.func.isRequired,
  renderInvestor: PropTypes.func.isRequired,
  activeLanguage: PropTypes.string,
  translations: PropTypes.objectOf(PropTypes.any),
  token: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
  }),
  isLoading: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.any),
  isAuthenticated: PropTypes.bool,
  isMeLoading: PropTypes.bool,
  isTranslationError: PropTypes.bool,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

DefaultLayout.defaultProps = {
  token: null,
  isLoading: true,
  user: {},
  isMeLoading: true,
  isAuthenticated: false,
  isTranslationError: false,
  activeLanguage: 'EN',
  translations: {},
  location: {
    pathname: '/',
    hash: '',
  },
};

const mapStateToProps = ({
  auth, i18n, featureFlags, me,
}) => ({
  translations: i18n.translations,
  activeLanguage: i18n.activeLanguage,
  isLoading: auth.isSessionLoading || i18n.isLoading || featureFlags.isLoading,
  user: auth.user,
  isMeLoading: me.isLoading,
  token: auth.token,
  isI18NLoading: i18n.isLoading,
  isAuthenticated: auth.isAuthenticated,
  isTranslationError: i18n.isError,
});

export default connect(
  mapStateToProps,
  {
    getMe,
    getUserSession,
    setFailedSession,
    getTranslations,
    getFeatureFlags,
    renderInvestor,
  },
)(withRouter(DefaultLayout));
