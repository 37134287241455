import Cookies from 'js-cookie';
import { REDIRECT_WARNING_DISABLED } from './actionTypes';

const defaultState = {
  redirectWarningDisabledUsers: !!Cookies.get('_redirectWarningDisabled') && JSON.parse(Cookies.get('_redirectWarningDisabled')),
};

export const redirectModal = (state = defaultState, { type, payload }) => {
  switch (type) {
    case REDIRECT_WARNING_DISABLED:
      // eslint-disable-next-line no-case-declarations
      const newNotification = state.redirectWarningDisabledUsers
        ? [...state.redirectWarningDisabledUsers.filter((email) => email !== payload), payload]
        : [payload];
      Cookies.set('_redirectWarningDisabled', JSON.stringify(newNotification), { expires: 30, path: '/' });
      return {
        ...state,
        redirectWarningDisabledUsers: newNotification,
      };

    default: return state;
  }
};
