/* eslint max-len: 0 */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row, Button,
} from 'reactstrap';
import Translate from '../../../i18n/Translate';
import './CookiesPolicy.scss';


const CookiesPolicy = ({ history }) => {
  const handleGotIt = () => {
    history.goBack();
  };

  return (
    <div className="accept-policy">
      <div className="animated fadeIn">
        <Row className="justify-content-between accept-policy__row m-auto">
          <Col xs="12" sm="6" md="10">
            <Card className="accept-policy__card transparent-card border-0 bg-transparent shadow-none">
              <CardBody className="text-center">
                <div className="mt-4">
                  <h2 className="mb-3 font-32">
                    <Translate translationKey="Texts.securitize-inc" />
                    {' '}
                    <br />
                    <Translate translationKey="Texts.cookie-policy" />
                  </h2>
                  <span className="text-muted font-17 text-left">
                    <p className="accept-policy__text">
                      <Translate translationKey="Texts.cookies-policy-1" />
                      &nbsp;
                    <a href="https://youradchoices.com/">https://youradchoices.com/</a>
                      &nbsp;
                      <Translate translationKey="Texts.cookies-policy-2" />
                      &nbsp;
                    <a href="https://www.youronlinechoices.eu">www.youronlinechoices.eu</a>
                      &nbsp;
                      <Translate translationKey="Texts.cookies-policy-3" />
                      <br />
                      <br />
                      <Translate translationKey="Texts.cookies-policy-4" />
                    </p>
                    <p>
                      <Translate translationKey="Texts.if-questions" />
                      {' '}
                      <a
                        rel="noopener noreferrer"
                        href="mailto:investorsupport@securitize.io"
                        target="_blank"
                      >
                        <Translate translationKey="Texts.contact-us" />
                      </a>
.
                    </p>
                  </span>
                </div>
              </CardBody>
            </Card>
            <div className="d-flex align-items-center justify-content-center">
            <Col md="3">
              <Button
                block
                className="mt-lg-5 py-3 text-white"
                color="primary"
                onClick={handleGotIt}
              >
                <Translate translationKey="Texts.got-it" />
              </Button>
            </Col>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

CookiesPolicy.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(CookiesPolicy);
