import { EventsManager } from '@securitize/events-mfe';

const RT_GW_BASE_URL = process.env.REACT_APP_SID_RT_GW_BASE_URL;
const RT_GW_BASE_PATH = process.env.REACT_APP_SID_RT_GW_BASE_PATH;

export class EventsManagerWrapper {
  static init(authToken) {
    if (!RT_GW_BASE_URL) {
      return;
    }

    try {
      EventsManager.close();
      EventsManager.init({
        host: RT_GW_BASE_URL,
        path: RT_GW_BASE_PATH,
        authToken,
      });
    } catch (error) {
      /* */
    }
  }

  static close() {
    if (!RT_GW_BASE_URL) {
      return;
    }
    try {
      EventsManager.close();
    } catch (error) {
      /* */
    }
  }
}
