import { translate } from '../../../i18n/Translate';
import { successToast } from '../../../components/Toast';

import {
  createLegalSignersNext, fetchInvestorSignerNext,
  deleteLegalSignerNext as _deleteLegalSignerNext,
  editInvestorSignerNext as _editInvestorSignerNext,
} from './actionTypes';

const defaultState = {
  isInitialized: false,
  isLoading: false,
  isError: false,
  legalSignersObj: {},
  data: [],
};

const deleteLegalSignerNext = _deleteLegalSignerNext();
const editInvestorSignerNext = _editInvestorSignerNext();

const legalSignerArrayToObj = (signersArray) => signersArray.reduce((signers, signer) => {
  signers[signer.signerId] = signer;
  return signers;
}, {});

export const legalSigners = (state = defaultState, { type, payload }) => {
  switch (type) {
    case createLegalSignersNext.INIT:
    case fetchInvestorSignerNext.INIT:
      return {
        ...state,
        isLoading: true,
        isInitialized: true,
      };
    case editInvestorSignerNext.SUCCESS:
      window.setTimeout(() => successToast(translate('Texts.changes-saved')), 0);
      // eslint-disable-next-line no-case-declarations
      const indexToReplace = state.data.findIndex((item) => item.signerId === payload.signerId);
      // eslint-disable-next-line no-case-declarations
      const newArr = state.data.slice();
      if (indexToReplace !== -1) {
        newArr.splice(indexToReplace, 1, payload);
      }
      return {
        ...state,
        data: newArr,
        legalSignersObj: {
          ...state.legalSignersObj,
          [payload.signerId]: payload,
        },
        isLoading: false,
        isError: false,
      };
    case createLegalSignersNext.SUCCESS:
    case fetchInvestorSignerNext.SUCCESS:
    case deleteLegalSignerNext.SUCCESS:
      return {
        ...state,
        legalSignersObj: legalSignerArrayToObj(payload),
        data: payload,
        isLoading: false,
        isError: false,
      };
    case createLegalSignersNext.ERROR:
    case fetchInvestorSignerNext.ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    default:
      return state;
  }
};
