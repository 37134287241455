import Toast from './Toast';
import {
  infoToast as _infoToast,
  successToast as _successToast,
  errorToast as _errorToast,
} from './Toasts';

export default Toast;
export const infoToast = _infoToast;
export const successToast = _successToast;
export const errorToast = _errorToast;
