import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import React, { useState } from 'react';
import './ServerErrorPage.scss';
import boxImg from './box-image.svg';

const ServerErrorPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="server-error-page d-flex align-items-center justify-content-center">
      <div className="animated fadeIn server-error-page__content  d-flex align-items-center flex-column">
        <img src={boxImg} alt="error" />
        <div className="font-20 mt-5">
          Something went wrong!
        </div>
        <div className="mt-4 text-center">
          We have problems to load this page.
          <br />
          Please, try again.
        </div>
        <LaddaButton
          className="btn m-0 p-3 mt-5 btn-primary btn-block font-weight-bold text-white"
          loading={isLoading}
          color="primary"
          data-style={ZOOM_OUT}
          onClick={() => {
            setIsLoading(true);
            window.location.reload();
          }}
        >
          Refresh
        </LaddaButton>
      </div>
    </div>
  );
};

export default ServerErrorPage;
