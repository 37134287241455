import { createHttpAction } from '../../http/http';
import {
  AUTHENTICATE_TFA_FLOW,
  AUTHENTICATE_TFA_FLOW_PING,
  REFRESH_TFA_FLOW_SESSION,
  SUBMIT_TFA_VERIFICATION,
  TFA_FLOW_LIVENESS,
  UPDATE_USER_INPUTS,
} from './actionTypes';

export const authenticateMfaFlow = (
  { email, password }, onSuccess = () => undefined, onError = () => undefined,
) => createHttpAction(
  AUTHENTICATE_TFA_FLOW,
  { body: { email, password } }, { onError, onSuccess },
).action;
export const refreshMfaFlowSession = () => createHttpAction(REFRESH_TFA_FLOW_SESSION).action;

export const getTfaLiveness = (onSuccess = () => undefined, onError = () => undefined) => createHttpAction(
  TFA_FLOW_LIVENESS, undefined,
  { onSuccess, onError },
).action;

export const submitTfaFlow = (data, onSuccess = () => undefined, onError = () => undefined) => createHttpAction(
  SUBMIT_TFA_VERIFICATION, { body: data },
  { onSuccess, onError },
).action;

export const pingTfaSession = (onSuccess = () => undefined, onError = () => undefined) => createHttpAction(
  AUTHENTICATE_TFA_FLOW_PING, undefined,
  {
    onSuccess,
    onError,
  },
).action;

export const updateUserInputs = (payload) => ({
  type: UPDATE_USER_INPUTS,
  payload,
});

