import { updateLegalInfoNext } from '../../verificationProfile/LegalInformation/actionTypes';
import { getPersonalInfoNext } from './actionTypes';
import { updatePersonalInfoNext } from '../../verificationProfile/PersonalInfo/actionTypes';
import { updatePhoneNumberNext } from '../../verificationProfile/TelephoneNumber/actionTypes';

const defaultState = {
  isInitialized: false,
  isLoading: false,
  isError: false,
  errorType: '',
  data: {},
};

export const personalInfo = (state = defaultState, { type, payload }) => {
  switch (type) {
    case getPersonalInfoNext.INIT:
      return {
        ...state,
        isLoading: true,
      };

    case getPersonalInfoNext.SUCCESS:
    case updatePersonalInfoNext.SUCCESS:
    case updatePhoneNumberNext.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
        isLoading: false,
        isInitialized: true,
        errorType: '',
        isError: false,
      };

    case updateLegalInfoNext.SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { tax, ...rest } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          ...rest,
        },
        isLoading: false,
        isInitialized: true,
        errorType: '',
        isError: false,
      };

    case getPersonalInfoNext.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorType: payload.message,
      };

    default:
      return state;
  }
};
