import React, { useEffect, useState } from 'react';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import {
  Alert, Button, Input, Label, ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
import InputGroup from 'reactstrap/es/InputGroup';
import InputGroupAddon from 'reactstrap/es/InputGroupAddon';
import InputGroupText from 'reactstrap/es/InputGroupText';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TFADigits from '../TFADigits';
import Translate from '../../i18n/Translate';
import ModalComponent from './ModalComponent';
import gAuth from '../../assets/img/other/g_auth.svg';
import authy from '../../assets/img/other/authy.svg';
import { confirmTFACode } from '../../views/Profile/AccountPage/actions';
import './TwoFactorModal.scss';

const SetQRTwoFAModal = ({
  onToggle,
  isOpen,
  loading,
  confirmTFACode,
  isError,
  errorType,
  email,
  options,
}) => {
  const [fullCode, setCodeState] = useState('');
  const [copied, setCopied] = useState(false);
  const setTwoFACode = (code) => {
    setCodeState(code);
  };

  useEffect(() => {
    if (fullCode.length === 6) {
      confirmTFACode(fullCode, options.password);
    }
  }, [fullCode]);

  const handleSendCode = () => {
    confirmTFACode(fullCode, options.password);
  };

  return (
    <ModalComponent
      className="set-qr-two-fa-modal"
      title="Texts.set-2fa"
      isOpen={isOpen}
      onToggle={onToggle}
      name="SetQRTwoFA"
      counter="2 / 2"
    >
      <>
        <div className="pt-3 mb-3">
          <h5 className="font-16 text-center pb-1 m-0">
            1.
            {' '}
            <Translate translationKey="2fa.download-app" />
          </h5>
          <div className="d-flex align-items-center justify-content-center mt-4 pl-4">
            <img src={gAuth} alt="" className="p-1" />
            <p className="apps-description font-16 ml-3 m-0">
              <Translate translationKey="2fa.google-authenticator" />
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-center mt-4 pl-4">
            <img src={authy} alt="" className="p-1" />
            <p className="apps-description font-16 ml-3 m-0">
              <Translate translationKey="2fa.authy-app" />
            </p>
          </div>
        </div>
        <div className="mb-3 pt-4">
          <h5 className="font-16 text-center">
            2.
            {' '}
            <Translate translationKey="2fa.scan-code" />
          </h5>
          <div className="QRCode mx-auto mt-3 p-2">
            <QRCode
              value={`otpauth://totp/securitizeID:${email}?secret=${options.seed}&issuer=securitize`}
              size={96}
            />
          </div>

          <div className="mx-auto max-width-330 mt-3">
            <Label
              htmlFor="backup-key"
              className="font-weight-bold text-muted"
            >
              <Translate translationKey="Texts.backup-key" />
            </Label>
            <InputGroup>
              <Input
                disabled
                id="backup-key"
                type="text"
                className="px-3 py-4"
                value={options.seed}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <CopyToClipboard
                    text={options.seed}
                    onCopy={() => setCopied(true)}
                  >
                    <Button
                      color="link"
                      className="p-0"
                    >
                      <i className={`icon-copy font-20 text-${copied ? 'primary' : 'muted'}`} />
                    </Button>
                  </CopyToClipboard>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </div>
        </div>
        <div>
          <TFADigits
            id="twofa-digits"
            callback={setTwoFACode}
          />
        </div>
        <ModalFooter className="border-0 p-0 flex-column">
          <LaddaButton
            className="btn m-0 p-3 btn-primary btn-block font-weight-bold text-white"
            loading={loading}
            color="primary"
            data-style={ZOOM_OUT}
            onClick={handleSendCode}
            disabled={fullCode.length !== 6}
            type="submit"
          >
            <Translate translationKey="Texts.set-2fa" />
          </LaddaButton>
          <Button className="m-0 mt-1" size="lg" color="link" block onClick={onToggle}>
            <Translate translationKey="Texts.do-later" />
          </Button>
        </ModalFooter>
        {
          isError && (
            <div className="mt-2 mb-0 form-group">
              <Alert color="danger mb-0">
                <Translate translationKey={errorType} />
              </Alert>
            </div>
          )
        }
      </>
      `
    </ModalComponent>
  );
};

SetQRTwoFAModal.propTypes = {
  onToggle: PropTypes.func.isRequired,
  confirmTFACode: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  isError: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  errorType: PropTypes.string,
  email: PropTypes.string.isRequired,
};
SetQRTwoFAModal.defaultProps = {
  errorType: '',
};

const mapStateToProps = ({
  helpers: {
    modal,
    options,
  },
  auth: { user },
}) => ({
  options,
  isError: modal.isError,
  loading: modal.loading,
  errorType: modal.errorType,
  email: user.email,
});

export default connect(
  mapStateToProps,
  {
    confirmTFACode,
  },
)(SetQRTwoFAModal);
