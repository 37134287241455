import React, { useState } from 'react';
import {
  Button, FormGroup, Input, Label, ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Translate, { translate } from '../../../i18n/Translate';
import ModalComponent from '../ModalComponent';
import './RedirectionWarningModal.scss';
import logo from '../../../assets/img/brand/securitize-logo.svg';
import { disableRedirectWarning } from '../../../utils/helpers/actions';

const RedirectionWarningModal = ({
  onToggle, isOpen, options: { issuerData, redirect, onClose }, disableRedirectWarning, email,
}) => {
  const [check, onChangeCheck] = useState(false);

  const onAccept = () => {
    if (check) disableRedirectWarning(email);
    redirect();
    onToggle();
  };

  const onCancel = () => {
    if (check) disableRedirectWarning(email);
    if (onClose) onClose();
    onToggle();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onToggle={onCancel}
      name="RedirectionWarningModal"
      className="redirect-modal-container"
    >
      <div className="redirect-modal-header mt-3">
        <div className="redirect-modal-header__logo-container">
          <img src={logo} alt={translate('Texts.securitize')} />
        </div>
        <div className="redirect-modal-header__arrow-container">
          <i className="redirect-modal-header__arrow-container__darkest icon-arrow_right font-20" />
          <i className="redirect-modal-header__arrow-container__medium icon-arrow_right font-20" />
          <i className="redirect-modal-header__arrow-container__lightest icon-arrow_right font-20" />
        </div>
        <div className="redirect-modal-header__logo-container">
          <img alt="issuer logo" src={issuerData.logo || logo} />
        </div>
      </div>
      <div className="d-flex flex-column align-items-center mb-3">
        <h4 className="modal-title font-20">
          <Translate translationKey="Texts.being-redirected" />
          {` ${issuerData.name} `}
          <Translate translationKey="Texts.website" />
        </h4>
      </div>
      <p className="modal-text font-16 m-0 text-center">
        <Translate
          translationKey="Texts.redirection-disclaimer"
        />
      </p>
      <FormGroup check className="checkbox d-flex align-items-center justify-content-center mt-4 pt-2 p-0">
        <Input
          id="redirect-dont-show"
          className="form-checkbox"
          type="checkbox"
          name="checkbox1"
          onChange={() => onChangeCheck(!check)}
        />
        <Label check className="form-check-label font-14 subtext" htmlFor="redirect-dont-show">
          <Translate translationKey="Texts.dont-show-again" />
        </Label>
      </FormGroup>
      <ModalFooter className="border-0 p-0 flex-column">
        <Button
          id="redirect-accept"
          className="m-0"
          color="primary"
          size="lg"
          block
          onClick={onAccept}
        >
          <Translate translationKey="Texts.go-to" />
          {` ${issuerData.name}`}
        </Button>
      </ModalFooter>
    </ModalComponent>
  );
};

RedirectionWarningModal.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    redirect: PropTypes.func.isRequired,
    issuerData: PropTypes.shape({
      name: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
    }),
    onClose: PropTypes.func,
  }).isRequired,
  disableRedirectWarning: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

const mapStateToProps = ({ helpers: { modal, options }, auth: { user } }) => ({
  options,
  isError: modal.isError,
  loading: modal.loading,
  tfaEnabled: !!(user && user.tfaEnabled),
  email: user.email,
});

export default connect(mapStateToProps, { disableRedirectWarning })(RedirectionWarningModal);
