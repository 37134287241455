import {
  getDocuments,
} from './actionTypes';

const defaultState = {
  isLoading: true,
  isError: false,
  data: {},
};

export const documents = (state = defaultState, { type, payload }) => {
  switch (type) {
    case getDocuments.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: {},
      };
    case getDocuments.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: payload,
      };
    case getDocuments.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: {},
      };
    default:
      return state;
  }
};
