import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect, useSelector } from 'react-redux';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { NavItem } from 'reactstrap';
import './MainMenu.scss';
import { useMediaQuery } from 'react-responsive';
import { WhTooltip } from '@securitize/reactjs-sec-ui';
import Translate, { translate } from '../../../../i18n/Translate';
import logo from '../../../../assets/img/brand/securitize-logo.svg';
import logoFull from '../../../../assets/img/brand/securitize_logo_with_text.svg';
import WizardMenu from './WizardMenu';
import { useVerificationRoutes } from '../../../../views/Profile/verificationProfile/routes';
import { MixPanel } from '../../../../utils/helpers/mixPanel';
import useMarketsOrOfferingsRedirect from '../../../../components/useMarketsOrOfferingsRedirect/useMarketsOrOfferingsRedirect';
import SidebarNav from '../../../../components/SidebarNav';
import { ReactComponent as IconPortfolio } from '../../../../assets/img/other/side-bar-portfolio.svg';
import { ReactComponent as IconInvest } from '../../../../assets/img/other/side-bar-invest.svg';
import { ReactComponent as IconTrade } from '../../../../assets/img/other/side-bar-trade.svg';
import { ReactComponent as IconWalletOpen } from '../../../../assets/img/other/side-bar-wallet-open.svg';

const MainMenu = ({
  authenticated, user, currentSigner, isAuthenticated,
}) => {
  const { pathname } = useLocation();
  const { getVerificationRoutes } = useVerificationRoutes();
  const routes = getVerificationRoutes(false);
  const isWizard = pathname && isAuthenticated && pathname.startsWith('/profile/verification/');
  const isSuitabilityWizard = pathname && isAuthenticated && pathname.startsWith('/profile/suitability');
  const { data: featureFlags } = useSelector(({ featureFlags }) => featureFlags);
  const { handleRedirect } = useMarketsOrOfferingsRedirect();
  const isDisabledByRole = user?.role !== 'admin';
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const isAssetDetailsPath = pathname.includes('asset-details');
  const isAssetDetailsMobile = isAssetDetailsPath && isMobile;
  const ENABLE_PRIMARY_MARKET_MFE = process.env.REACT_APP_ENABLE_PRIMARY_MARKET_MFE === 'true';
  const ENABLE_ATS_MFE = process.env.REACT_APP_ENABLE_ATS_MFE === 'true';

  const [holdings, wallets, trading, primaryMarkets] = ['holdings', 'wallets', 'trading', 'markets'];
  const [activeTab, setActiveTab] = useState(holdings);

  const isHoldingsActive = () => ['^/portfolio$', '^/account$', '^/asset-details']
    .some((path) => new RegExp(path, 'i').test(pathname));
  const isPrimaryMarketsActive = () => new RegExp('^/primary-market|^/market', 'i').test(pathname);
  const isWalletsActive = () => new RegExp('^/wallets$', 'i').test(pathname);
  const isTradingActive = () => new RegExp('^/secondary-market', 'i').test(pathname);

  useEffect(() => {
    const primaryTab = isPrimaryMarketsActive() && primaryMarkets;
    const holdingsTab = isHoldingsActive() && holdings;
    const walletTab = isWalletsActive() && wallets;
    const tradingTab = isTradingActive() && trading;
    const selectedTab = primaryTab || holdingsTab || walletTab || tradingTab;
    setActiveTab(selectedTab || holdings);
  }, [pathname]);

  const wrapComponentWithTooltip = (linkComponent, { isWithTooltip, tooltipTitle, tooltipBody }) => (
    isWithTooltip ? (
      <WhTooltip
        text={(
          <div className={classnames('sec-id-main-menu__tooltip', { 'pb-5': isMobile })}>
            <div className="sec-id-main-menu__tooltip--title">
              <Translate translationKey={tooltipTitle} />
            </div>
            <div className="sec-id-main-menu__tooltip--body">
              <Translate translationKey={tooltipBody} />
            </div>
          </div>
        )}
        position="bottomLeft"
      >
        { linkComponent }
      </WhTooltip>
    ) : linkComponent
  );

  return (
    !isAuthenticated || isAssetDetailsMobile ? null
      : (
        <SidebarNav isWizard={isWizard}>
          <NavItem className="logo-nav-securitize">
            <Link id="link-home" to="/">
              {isWizard ? (
                <img
                  src={logoFull}
                  className={classnames('nav-img-avatar')}
                  alt={translate('Texts.securitize')}
                />
              ) : (
                <img src={logo} className="nav-img-avatar" alt={translate('Texts.securitize')} />
              )}
            </Link>
          </NavItem>
          {
            !authenticated ? '' : isWizard ? (
              <WizardMenu user={user} currentType={currentSigner.signerType} routes={routes} />
            ) : isSuitabilityWizard
              ? false
              : (
                [
                  <NavItem key="holdings" active={activeTab === holdings}>
                    <Link
                      id="home-page-nav-holdings"
                      to="/portfolio"
                      onClick={() => MixPanel.track('holdings-menu-click')}
                    >
                      <IconPortfolio className="nav-img-action nav-menu-img icon-coins" />
                      <span className="sec-id-main-menu__title text-truncate">
                        <Translate translationKey="Texts.holdings" />
                      </span>
                    </Link>
                  </NavItem>,
                  featureFlags.marketTab && (
                    <NavItem key="markets" active={activeTab === primaryMarkets}>
                      <Link
                        id="home-page-nav-market"
                        to={ENABLE_PRIMARY_MARKET_MFE ? '/primary-market' : '/market'}
                      >
                        <IconInvest className="nav-img-action nav-menu-img icon-portfolio" />
                        <span className="sec-id-main-menu__title">
                          <Translate translationKey="Texts.primary-offerings" />
                        </span>
                      </Link>
                    </NavItem>
                  ),
                  featureFlags.tradingTab && (
                    <NavItem key="trading" active={activeTab === trading}>
                        {
                          wrapComponentWithTooltip(
                            (
                              <Link
                                id="home-page-nav-secondary-market"
                                to={ENABLE_ATS_MFE ? '/secondary-market' : '/'}
                                onClick={(e) => {
                                  if (!ENABLE_ATS_MFE) {
                                    e.preventDefault();
                                    handleRedirect(`${window.location.origin}/secondary-market`, pathname, true);
                                  }
                                }}
                                className={isDisabledByRole ? 'disabled-by-role' : undefined}
                              >
                                <IconTrade className={classnames('nav-img-action nav-menu-img icon-trading', {
                                  'disabled-by-role': isDisabledByRole,
                                })}
                                />
                                <span className="sec-id-main-menu__title">
                                  <Translate translationKey="Texts.trading" />
                                </span>
                              </Link>
                            ),
                            {
                              isWithTooltip: isDisabledByRole,
                              tooltipTitle: 'Texts.restricted-access',
                              tooltipBody: user?.role === 'viewer'
                                ? 'Texts.restricted-access-viewer-body'
                                : 'Texts.restricted-access-signer-body',
                            },
                          )
                        }
                    </NavItem>
                  ),
                  <NavItem key="wallets" active={activeTab === wallets}>
                    <Link id="home-page-nav-wallets" to="/wallets" onClick={() => MixPanel.track('wallets-menu-click')}>
                      <IconWalletOpen className="nav-img-action nav-menu-img icon-wallet" />
                      <span className="sec-id-main-menu__title">
                        <Translate translationKey="Texts.wallets" />
                      </span>
                    </Link>
                  </NavItem>,
                ]
              )
          }
        </SidebarNav>
      )
  );
};


MainMenu.propTypes = {
  authenticated: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.any),
  currentSigner: PropTypes.objectOf(PropTypes.any),
};

MainMenu.defaultProps = {
  authenticated: false,
  isAuthenticated: false,
  user: null,
  currentSigner: {},
};

const mapStateToProps = ({
  auth: { user, isAuthenticated },
  profile: { verification: { currentSigner } },
}) => ({
  isLoading: false,
  user,
  currentSigner,
  isAuthenticated,
});

export default connect(mapStateToProps)(withRouter(MainMenu));
