import useragent from 'useragent';
import UaParser from 'ua-parser-js';
import { sessionsGetNext, sessionsLogoutNext } from './actionTypes';

const defaultState = {
  isInitialized: false,
  isLoading: true,
  isError: false,
  isDeleting: false,
  errorType: '',
  sessions: [],
};

export const sessions = (state = defaultState, { type, payload }) => {
  switch (type) {
    case sessionsLogoutNext.INIT:
      return {
        ...state,
        isDeleting: true,
      };
    case sessionsLogoutNext.ERROR:
      return {
        ...state,
        isDeleting: false,
      };
    case sessionsLogoutNext.SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case sessionsGetNext.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorType: '',
      };
    case sessionsGetNext.ERROR:
      return {
        ...state,
        isInitialized: true,
        isLoading: true,
        isError: false,
        errorType: payload.message || 'Errors.server-error',
      };
    case sessionsGetNext.SUCCESS:
      return {
        ...state,
        isInitialized: true,
        isLoading: false,
        isError: false,
        errorType: '',
        sessions: (payload || []).map(
          (item) => {
            const uAgent = new UaParser(item.userAgent);
            const agent = useragent.parse(item.userAgent);
            item.agent = {
              os: agent.os.family,
              device: agent.device.family,
              deviceType: uAgent.getDevice().type || 'laptop',
            };
            return item;
          },
        ),
      };
    default:
      return state;
  }
};
