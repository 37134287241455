import Cookies from 'js-cookie';
import { registerLocale } from 'i18n-iso-countries';
import moment from 'moment';
import { createHttpAction } from '../http/http';
import {
  i18nNext,
  SET_LANGUAGE,
} from './actionTypes';

export const setActiveLanguage = (language = 'EN') => {
  Cookies.set('active-language', language, { expires: 31, path: '/' });
  return {
    type: SET_LANGUAGE,
    payload: language,
  };
};

export const getTranslations = (language = 'EN') => createHttpAction(
  i18nNext,
  {
    params: {
      language: (language || 'EN').toLowerCase(),
    },
  },
  {
    onSuccess(response) {
      const languages = Object.keys(response.languages).map((key) => key.toLowerCase());
      languages.forEach(
        (language) => {
          registerLocale(require(`i18n-iso-countries/langs/${language}.json`));  // eslint-disable-line
        },
      );
      let activeLang = language.toLowerCase();
      if (activeLang === 'en') activeLang = 'en-gb';
      if (activeLang === 'zh') activeLang = 'zh-cn';
      require(`moment/locale/${activeLang}.js`); // eslint-disable-line
      moment.locale(activeLang);
      return setActiveLanguage(language);
    },
  },
).action;
